import React, { PureComponent } from 'react';

class TimingRow extends PureComponent {
    state = { 

     }

    render() {

    //check if the number is even
    if(this.props.driver.Position % 2 == 0) {
        var rowClass = "even"
    } else {
        var rowClass = "odd"
    }
	

    var battleButton = <button attr1="battle" attr2={this.props.driver.CompetitorId} onClick={this.props.battleCalc} className="timingBUTTON">Battle</button>



    //check if in Battle
    var battleQuestion = this.props.Controls.BattleDrivers.indexOf(parseInt(this.props.driver.CompetitorId))
    if (battleQuestion > -1){
        var rowClass = "battle"
        var battleButton = <button attr1="battle" attr2={this.props.driver.CompetitorId} onClick={this.props.battleCalc} className="timingBUTTON">Remove</button>
    }

    //work out sector colours
    if (this.props.driver.CurrentLap && this.props.Session.Sectors){
        if (this.props.driver.CurrentLap.Sectors){
            var driverSectors = this.props.driver.CurrentLap.Sectors
            var i = 0
            var sectorColours = []
            while (i < driverSectors.length){
                var sectors = this.props.Session.Sectors
                var sectorIndex = sectors.findIndex( x => x.ID == parseInt(driverSectors[i].SectorID))
                if (this.props.Session.Sectors[sectorIndex].BestTime == driverSectors[i].SectorTime){
                    sectorColours[sectorIndex] = "fastestPink"
                } else if (driverSectors[i].SectorTime > driverSectors[i].BestTime){
                    sectorColours[sectorIndex] = "normal"
                } else if (driverSectors[i].SectorTime == driverSectors[i].BestTime){
                    sectorColours[sectorIndex] = "PositiveGreen"
                }
                i++
            }
        }
        
    }

    //check if enough laps for comparison
    var compareQuestion = this.props.driver.LapData
    if (compareQuestion != undefined){
        if (compareQuestion.length < 4){
        var compareButton = <button disabled attr1="compare" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend} className="timingBUTTON">Comp</button>
        } else {
            var compareButton = <button attr1="compare" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend} className="timingBUTTON">Comp</button>
        }
    } else {
        var compareButton = <button disabled attr1="compare" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend} className="timingBUTTON">Comp</button>
    }

    var pointer = 'all'
	if(this.props.Session){
		if (this.props.driver.Behind == "+DNS"){
			var opacity = 0
            pointer = 'none'
		} else if (this.props.Session.TSLGrouped == 1 && this.props.driver.GroupNumberOfLaps > 1) {
			var opacity = 1
		} else if (this.props.Session.State == "Running" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
            pointer = 'none'
		} else if (this.props.Session.State == "ChequeredFlag" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
            pointer = 'none'
		} else {
            var opacity = 1
        }
	}    

    //If Qualifying Session - Calculate the gap to the leader to display in col4 content
    var gap = this.props.driver.Behind
    if (this.props.Session.SessionType == 'Qual'){
        if(this.props.driver.BestLaptime){
            if (parseInt(this.props.driver.Position) == 1){
                gap = this.props.msToTime(this.props.driver.BestLaptime*1000)
            } else {
                var p1Index = this.props.Session.Competitors.findIndex(x => x.Position == 1)

                if(p1Index != - 1){
                    var p1Best = this.props.Session.Competitors[p1Index].BestLaptime*1000
                    var driverBest = this.props.driver.BestLaptime*1000

                    var difference =  driverBest - p1Best

                    gap = "+" + this.props.msToTime(difference)
                } else {
                    gap = ""
                }
                
            }
        } else {
            gap = "No Time"
        }
    } 

            return ( 
                <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.Position*35)-35+"px", pointerEvents: pointer}}>
                    {/* {battleButton}
                    <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>Expand</button>
                    {compareButton} */}
                    <div className="timingButtonBlank"></div>
                    <div className="timingPOS">{this.props.driver.Position}</div>
                    <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                    <div className="timingNAME">{this.props.driver.CompetitorName}</div>
                    <div className="timingLAPS">{this.props.driver.NumberOfLaps}</div>
                    <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                    <div className="timingBEHIND">{gap}</div>
                    <div className="timingGAP">{this.props.driver.Gap}</div>
                    <div className="timingBEST">{this.props.msToTime(this.props.driver.BestLaptime*1000)}</div>
                    <div className="timingPITS">{this.props.driver.GridPosition}</div>
                    {/* <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img> */}
                </div>
            )
    

        
    }
}

export default TimingRow;