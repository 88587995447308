import React, { PureComponent } from 'react';
import './stylesheets/clientState.css';

class ClientState extends PureComponent {
    state = { 

     }

    render() {
        return(         
            <div className='box' style={{backgroundColor: this.props.color, height: "250px"}}>
                <div className='boxHeader' onClick={this.props.collapse}>
                    CLIENT STATE
                </div>
                <div className='clientLeft'>
                    <div className='clientState'>
                        <input type="checkbox" id="Flags" name="Flags" defaultChecked={this.props.ClientStates.CountryFlags}/> Flags </div>
                    <div className='clientState'>
                        <input type="checkbox" id="Photos" name="Photos" defaultChecked={this.props.ClientStates.DriverPhotos}/> Photos </div>
                    <div className='clientState'>
                        <input type="checkbox" id="flSponsor" name="flSponsor" defaultChecked={this.props.ClientStates.FlSponsor}/> Fastest Sponsor </div>
                    <div className='clientState'>
                        <input type="checkbox" id="resultSponsor" name="resultSponsor" defaultChecked={this.props.ClientStates.ResultsSponsor}/> Result Sponsor </div>
                    <div className='clientState'>
                        <input type="checkbox" id="towerSponsor" name="towerSponsor" defaultChecked={this.props.ClientStates.TowerSponsor}/> Tower Sponsor </div>
                    <button onClick={this.props.submitClientStates}>SUBMIT</button>
                    
                </div>
                <div className='clientRight'>
                   <div className='clientState2'>Timing Name 
                    <input id="TimingName" className='clientText' style={{width: "100px"}}type="text" defaultValue={this.props.ClientStates.TimingName}></input></div>
                    
                    <div className='clientState2'>Timing System 
                    <input id="TimingSystem" className='clientText' style={{width: "100px"}}type="text" defaultValue={this.props.ClientStates.TimingSystem}></input></div>

                    <div className='clientState2'>Tower Height (px) 
                    <input id="TowerHeight" className='clientNumber' style={{width: "100px"}} type="number" defaultValue={parseInt(this.props.ClientStates.TowerHeadHeight)}></input></div>

                    <div className='clientState2'>Tower Sponsor W(px)   
                    <input id="TowerWidth" className='clientNumber' style={{width: "100px"}} type="number" defaultValue={parseInt(this.props.ClientStates.TowerSponsorWidth)}></input></div> 
                    
                    <div className='clientState2'>Tower Sponsor H(px)   
                    <input id="TowerSponsorHeight" className='clientNumber' style={{width: "100px"}} type="number" defaultValue={parseInt(this.props.ClientStates.TowerSponsorHeight)}></input></div> 

                    <div className='clientState2'>Tower Logo 
                    <input id="TowerLogo" className='clientText' style={{width: "100px"}}type="text" defaultValue={this.props.ClientStates.TowerSponsorLogo}></input></div>

                    <div className='clientState2'>Advance
                    <input id="Advance" className='clientNumber' type="number" defaultValue={this.props.ClientStates.DefaultProgression} style={{width: "100px"}}/> </div>
                    
                </div>
                    
                    
            </div>
        )
    }
}

export default ClientState;