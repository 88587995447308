import React, { PureComponent } from 'react';
import JumboGridItem from './jumboGridItem';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

let alpha = params.alpha;

class JumboGridList  extends PureComponent { 
    render() {

        const gridBGStyles = {
            position: 'absolute',
            alignItems: 'start',
            backgroundColor: 'rgb(69,69,69)',
            padding: 0,
            marginTop: '0px',
            height: '100%',
            width: '100%',
            marginLeft: '0px',
            zIndex:-1,
            overflow: 'hidden',
          };
        
        const imageSlotStyles = {
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            backgroundColor: 'transparent',
            height: '-webkit-fill-available',
            width: '500px'
          };

        const seriesImgStyle = {

        }

        const gridCenterBG = {
            position: 'absolute',
            // backgroundColor: 'darkGrey',
            // width: '1920px',
            height: '698px',
            // marginLeft: '-88px',
            // marginTop: '-150px'
        }

        const gridRaceName = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50px',
            backgroundColor: '#ffffff',
            // marginLeft: '500px',
            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: '1px',
            color: '#000000',
            fontSize: '30px',
            fontFamily: 'Eurostile-Bold',
            width: 'fit-content',
            padding: '0px 50px',
            zIndex: 1,
        }

        var listContainerHeight = (parseInt(this.props.ClientStates.JumboGridRowHeight)+parseInt(this.props.ClientStates.JumboGridRowSpacing))*parseInt(this.props.ClientStates.JumboGridFixedPos)

        const raceNameBanner = {
            marginTop: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        }
        
        const gridListLeftStyle ={
            position: 'absolute',
            marginLeft: '86px',
            marginTop: '20px',
            overflow: 'hidden',
            backgroundColor: 'transparent',
            height: "920px",
            width: '826px',
            // height: '-webkit-fill-available'
        }

        const gridListRightStyle ={
            position: 'absolute',
            marginLeft: '987px',
            marginTop: '20px',
            overflow: 'hidden',
            backgroundColor: 'transparent',
            height: "920px",
            width: '826px',
            // height: '-webkit-fill-available'
        }

        

        

        if(alpha == "true"){
            // Create a shallow copy of the items and sort it
            var sortedItems = this.props.grid.slice().sort((a, b) => {
                return a.gridPosition - b.gridPosition;
            });

            var raceName = this.props.selectedRaceName

        } else {
            // Create a shallow copy of the items and sort it
            var sortedItems = this.props.Session.Competitors.slice().sort((a, b) => {
                return a.GridPosition - b.GridPosition;
            });

            var raceName = this.props.Session.SessionName.toUpperCase()
        }

        // Filter and map even items
        const evenItems = sortedItems
        .filter((item, index) => index % 2 === 0)

          // Filter and map odd items
        const oddItems = sortedItems
        .filter((item, index) => index % 2 !== 0)

        return (
            <div style={gridBGStyles}>
                {/* <div style={gridCenterBG}></div> */}
                {/* <img style={gridCenterBG} src={'images/KC-GRIDBG.png'}></img> */}
                
                <div style={gridListLeftStyle}>
                   {evenItems.map((driver, index) => (
                    <JumboGridItem key={index} id={index} side="left" driver={driver} {...this.props}/>
                    ))} 
                </div>

                <div style={gridListRightStyle}>
                    {oddItems.map((driver, index) => (
                    <JumboGridItem key={index} id={index} side="right" driver={driver} {...this.props}/>
                    ))} 
                </div>
                
          </div>
        )
    }
}

export default JumboGridList ;