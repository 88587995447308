import React, { PureComponent } from 'react';

class Session extends PureComponent {
    state = { 

     }

    render() {
        
        // Function to handle key press inside the text box
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                if (event.target.id == 'tickerName'){
                    var buttonToSubmit = document.getElementById('TickerNameButton')
                } else {
                    var buttonToSubmit = document.getElementById('SessionNameButton')
                }
                buttonToSubmit.click(); // Trigger submit on Enter key press in text box
            }
        };


        if(this.props.SessionType == "Qual"){
            var toggle = <div className='text'>Timed Session:<div class="toggle-switch"> <input type="checkbox" id="toggle" class="toggle-checkbox" checked={true} onClick={this.props.handleCheckBox}/><label class="toggle-label" for="toggle"><span class="toggle-button"></span></label></div></div>
        } else {
            var toggle = <div className='text'>Timed Session:<div class="toggle-switch"> <input type="checkbox" id="toggle" class="toggle-checkbox" onClick={this.props.handleCheckBox}/><label class="toggle-label" for="toggle"><span class="toggle-button"></span></label></div></div>
        }


        if (this.props.ClientStates.TimingSystem == "HS" || this.props.ClientStates.TimingSystem == "Speedhive"){
            return(         
                <div id='session' className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' onClick={this.props.collapse}>
                    SESSION
                </div>
                <div className='text'>Name: {this.props.Session.SessionName}</div>
                <div className='text'>
                    Timing Page:{' '}
                    <input type='text' id='timingPage' onChange={this.props.HSPage}/>
                </div>
                <div className='text'>
                    State:{' '}
                    <select id='state' value={this.props.Session.State} onChange={this.props.changeSessionState}>
                    <option value='Formation'>Formation</option>  
                    <option value='Starting'>Starting</option>  
                    <option value='Running'>Green</option>
                    <option value='RedFlag'>Red</option>
                    <option value='Yellow'>Yellow</option>
                    <option value='LastLap'>Final Lap</option>
                    <option value='ChequeredFlag'>Finished</option>
                    </select>
                    <button className="controlBUTTON" onClick={this.props.manualRaceStart}>Race Start</button>
                </div>
                
                <div className='text'>
                    Duration:{' '}
                    <input type='text' id='duration' defaultValue={this.props.Session.SD} onChange={this.props.changeDuration}/>
                </div>
                </div>
            )
        } else if (this.props.ClientStates.TimingSystem == "TSL"){
            return(         
            <div id='session' className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' onClick={this.props.collapse}>
                    SESSION INFO
                </div>
                <div className='text'>Name: {this.props.Session.SessionName}</div>
                <div className='text'>Circuit: {this.props.Session.tn}</div>
                <div className='text'>State: {this.props.Session.State}</div>
                <div className='text'>Duration: {this.props.Session.SD}</div>
                <div className='text'>Grouped: {this.props.Session.TSLGroupedType}</div>
            </div>
            )
        } else {
            return(         
                <div id='session' className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' onClick={this.props.collapse}>
                        SESSION INFO
                    </div>
                    <div className='text'>Name: <input onKeyUp={handleKeyPress}  id='sessionName' type='text' style={{width:'100px'}}></input><button id={'SessionNameButton'} onClick={this.props.changeSessionName}>Send</button></div>
                    <div className='text'>Ticker Name: <input onKeyUp={handleKeyPress}  id='tickerName' type='text' style={{width:'100px'}}></input><button id={'TickerNameButton'} onClick={this.props.changeTickerName}>Send</button></div>
                    {toggle}
                    <div className='text'>Circuit: {this.props.Session.tn}</div>
                    <div className='text'>State: {this.props.Session.State}</div>
                    <div className='text'>Duration: <input id='number' type='number' min='1' style={{width:'40px'}} defaultValue={this.props.RaceDuration}></input><button onClick={this.props.changeDuration}>Send</button></div>
                    
                </div>
                )
        }
        
    }
}

export default Session;