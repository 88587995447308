import React, { PureComponent } from 'react';
import DefaultRow from './TowerRowStates/default';



class TowerRows extends PureComponent {
    state = { 

     }
    render() { 
                return ( 
                    <DefaultRow {...this.props}/>
                );
            } 
     }


 
export default TowerRows;