import { transform } from 'lodash';
import React, { PureComponent } from 'react';
import '../stylesheets/TowerRows.css';

class DefaultRow extends PureComponent {    
    render() {
        var marginBoost = 0
        var photoClass = "photo-off"
        var winnerNum = ""
        //Opacity Calculations START
        if (this.props.driver.NumberOfLaps < 1){
            var opacity = 0
            marginBoost = 1000
            marginBoost = 0
        } else if (this.props.driver.seen == false) {
            var opacity = 0
            marginBoost = 10000000000
            marginBoost = 0
        } else {
            var opacity = 1
        }
        //Opacity Calculations END

        //Row Height Calculations Start
        if (this.props.driver.Expanded == true){
            var height = "75px" 
        } else {
            var height = this.props.ClientStates.TowerRowHeight
        }
        //Row Height Calculations End

        //Battle Calculations START
        if (this.props.Controls.BattleShow == true){
            var battleQuestion = this.props.Controls.BattleDrivers.indexOf(parseInt(this.props.driver.CompetitorId))
            if (battleQuestion != -1 && this.props.pages == false){
                var nameBG = "rgb(255 255 255 / 75%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion != -1 && this.props.pages == true && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(255 255 255 / 75%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion == -1 && this.props.pages == true && parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(0 0 0 / 85%)"
                var nameText = "#fff"
                marginBoost = 27
            } else if (parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && this.props.pages == false ) {
                var nameBG = "rgb(0 0 0 / 85%)"
                var nameText = "#fff"
                marginBoost = 27
            } else {
                var nameBG = "rgb(0 0 0 / 85%)"
                var nameText = "#fff"
            }
        } else {
            var nameBG = "rgb(0 0 0 / 85%)"
            var nameText = "#fff"
        }
        //Battle Calculations END

        //Smart Naming Calculations START
        // if(this.props.driver.CompetitorName){
        //     if (this.props.driver.CompetitorName){
        //         var competitorName = this.props.driver.CompetitorName
        //         var run = 0
        //         while (competitorName.length > 0 || run == 0){
        //             var nameArray = competitorName.split(",").join(" ").split(" ")

        //             if (run > 0){
        //                 var nameArray = competitorName.split("-").join(" ").split(" ")
        //                 var nameStart = nameArray[0]
        //                 var firstName = nameStart.substring(0,3*run+1)
        //             } else {
        //                 var firstName = nameArray[0][0]
        //             }

        //             if(nameArray.length == 2){
        //                 competitorName = competitorName
        //                 break
        //             } else {
        //                 nameArray.shift()
        //             }

        //             if(run == 0){
        //                 var competitorName = firstName+". "+nameArray
        //             } else {
        //                 var competitorName = firstName+" "+nameArray
        //             }
                    
        //             run++
        //             competitorName = competitorName.replace(/,/g, ' ');
        //             if (run > 5 || nameArray.length == 1 ){ break }
        //         }

        //     } else {
        //         var competitorName = this.props.driver.CompetitorName
        //     }
        // } else {
        //     var competitorName = "undefined"
        // }
        //Smart Naming Calculations END
        var competitorName = this.props.driver.CompetitorName

        var competitorName = competitorName.split(' ')

        var initial = competitorName[0][0]
        competitorName.shift()
        competitorName = competitorName.join(' ')

        competitorName = initial + ". "+ competitorName


        //Position Positive/Negative START
        if (this.props.driver.PositionChange > 0){
            var positionClass = undefined;
        } else if (this.props.driver.PositionChange < 0){
            var positionClass = undefined;
        } else {
            var positionClass = undefined;
        }
        //Position Positive/Negative END

        //extraBox & position class Calculations START
        var extraBoxClass = "extraBox transparent"
        var extraBoxContent = ""
        var extraBoxImgClass = "extraBoxImgHide"
        var tintMargin = 40
       if (this.props.driver.IsBestLapInRace == true && this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox Black"
            positionClass = "fastestPink positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = "5px"
        } else if (this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox Black"
            positionClass = "positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = 5
        } else if (this.props.driver.InPit == true){
            extraBoxClass = "extraBox StreamBlue"
            positionClass = "StreamBlue positionneutral"
            extraBoxContent = "PIT"
            tintMargin = 5
        } else if (this.props.driver.IsBestLapInRace == true && this.props.driver.NumberOfLaps > 1 && this.props.Session.SessionType != 'Qual' && this.props.Session.SessionType != 'Qualifying' && this.props.Session.SessionType != 'Practice'){
            extraBoxClass = "extraBox fastestPink"
            positionClass = "fastestPink positionneutral"
            extraBoxContent = "FL"
            tintMargin = 5
        }
        //extraBox & position class Calculations END

        //col4 content calculations START
        var col4Content = ""
        var col4BGColor = "rgb(0 0 0 / 65%)"
        var tintWidth = 310
        if (this.props.Controls.Col4 == "gap"){
            if (this.props.driver.Position == 1 && this.props.Session.SessionType != "Qual"){
                col4Content = "Gap"
            } else if (this.props.driver.Position == 1 && this.props.Session.SessionType == "Qual") {
                col4Content = this.props.msToTime(this.props.driver.BestLaptime*1000)
            } else if (this.props.Session.SessionType == "Qual") {
                col4Content = "+" + this.props.driver.Behind
            } else {
                col4Content = "+"+this.props.driver.Behind
            }
            tintWidth = 310 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "diff"){
            if (this.props.driver.Position == 1){
                col4Content = "Diff"
            } else {
                
                col4Content = "+"+this.props.driver.Gap
            }
            tintWidth = 310 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "pits"){
            col4Content = this.props.driver.ps
            tintWidth = 265 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "positions"){
            if (this.props.ClientStates.TimingSystem == "TSL" || this.props.ClientStates.TimingSystem == "rMonitor"){
                col4Content = this.props.driver.GridPosition - this.props.driver.Position
            } else {
                col4Content = (this.props.driver.GridPosition+1) - this.props.driver.Position
            }
            
            tintWidth = 265 + ( 40 - tintMargin)
            if (col4Content > 0){
                col4BGColor = "rgb(0 146 69 / 85%)"
                col4Content = "▲ " + col4Content
            } else if (col4Content < 0){
                col4BGColor = "rgb(193 39 45 / 85%)"
                col4Content = "▼ " + Math.abs(col4Content)
            } else {
                col4Content = "◀▶"
            }
        } else if (this.props.Controls.Col4 == "none"){
            tintWidth = 265
        }
        
        if (col4Content == "NaN Lap" || col4Content == "NaN Laps" || col4Content == "+null"){
            col4Content = ""
        }
        //col4 class calculations END

        //Retired Settings START
        if (this.props.driver.Retired == true){
            var rowTint = "rgb(0 0 0 / 70%)"
        } else {
            var rowTint = "transparent"
        }
        //Retired Settings END

        //Progression Settings Start
        var posColor = this.props.ClientStates.PrimaryColour

        var progressionNo = this.props.Controls.ProgressionPosition || this.props.ClientStates.DefaultProgression
        if (this.props.Controls.ProgressionShow == true){
            if (parseInt(this.props.driver.Position) > parseInt(progressionNo)){
                // marginBoost = marginBoost + 9
                posColor = '#ef0000'
            } else {
                // marginBoost = marginBoost + 0
            }
        }
        //Progression Settings End

        //Expand Content Start
        if (this.props.driver.Position == 1 && this.props.driver.TakenChequered == true && this.props.ClientStates.DriverPhotos == true){
            //Winner Data
            // var photoURL = this.props.driver.Picture + "-4.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-4.png"
            var firstSpaceIndex = this.props.driver.CompetitorName.indexOf(" ");
            var firstName = this.props.driver.CompetitorName.substring(0, firstSpaceIndex);
            var remainingName = this.props.driver.CompetitorName.substring(firstSpaceIndex + 1);
            var expandedHTML = <div className='expandedTextBox'><div className='winnerFirstName'>{firstName}</div><div className='winnerRemainingName'>{remainingName}</div><div className='winnerTeam'>{this.props.driver.TeamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
            competitorName = ""
            positionClass = "winnerPosition"
            winnerNum = "winnerNum"
        } else if (this.props.driver.Position == 1 ){
            //1st Position Data
            // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-3.png"
            var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'>{this.props.driver.TeamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle || this.props.driver.Chassis }</div></div>
        } else {
            //All other Positions Data
            // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-3.png"
            var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'>{this.props.driver.TeamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle || this.props.driver.Chassis}</div></div>
        }
        //Expand Content End

        //Expand Check Start
        if (this.props.Controls.Expand == true){
            if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == false){
                //Expand top 10
                var expandClass = "expand-on"
                col4BGColor = "rgb(0 0 0 / 85%)"
                photoClass = "photo-on"
                col4Content = ""
            } else if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == true && this.props.driver.Position > 10){
                //Expand after top 10
                var expandClass = "expand-on"
                col4BGColor = "rgb(0 0 0 / 85%)"
                col4Content = ""
                photoClass = "photo-on"
            } else {
                //Drivers after the expanded driver
                var expandClass = "expand-off"
                if (this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position && this.props.pages == false){
                    marginBoost = marginBoost + 72
                } else if (this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position > 10 && this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position && this.props.pages == true){
                    marginBoost = marginBoost + 72
                }
                
            }
        } else {
            var expandClass = "expand-off"
        }
        //Expand Check End
        if (!this.props.driver.Position){
            opacity = 0
        }

        //Calculate Margin - (Client Gap + Client Row Height) Multiplied by Position
        if (this.props.pages == true){
            var totInPage = parseInt(this.props.ClientStates.TowerTotalPos) - parseInt(this.props.ClientStates.TowerFixedPos)
            var pagesOffSet = (parseInt(this.props.ClientStates.TowerRowHeight) + parseInt(this.props.ClientStates.TowerRowSpacing)) * parseInt(this.props.ClientStates.TowerFixedPos)
            var totalPageHeight = (parseInt(this.props.ClientStates.TowerRowHeight) + parseInt(this.props.ClientStates.TowerRowSpacing)) * totInPage 
            pagesOffSet = pagesOffSet + totalPageHeight * (this.props.Controls.CurrentPage-1)
            var margin = (parseInt(this.props.ClientStates.TowerRowHeight) + parseInt(this.props.ClientStates.TowerRowSpacing)) * parseInt(this.props.driver.Position-1)
        } else {
            var margin = (parseInt(this.props.ClientStates.TowerRowHeight) + parseInt(this.props.ClientStates.TowerRowSpacing)) * parseInt(this.props.driver.Position-1)
            var pagesOffSet = 0
        }


        //If Qualifying Session - Calculate the gap to the leader to display in col4 content
        if (this.props.Session.SessionType == 'Qual'){

            if(this.props.driver.BestLaptime){
                if (parseInt(this.props.driver.Position) == 1){
                    col4Content = this.props.msToTime(this.props.driver.BestLaptime*1000)
                } else {
                    var p1Index = this.props.Session.Competitors.findIndex(x => x.Position == 1)
                    
                    if(p1Index != - 1){
                        var p1Best = this.props.Session.Competitors[p1Index].BestLaptime*1000
                        var driverBest = this.props.driver.BestLaptime*1000

                        var difference =  driverBest - p1Best

                        col4Content = "+" + this.props.msToTime(difference)
                    } else {
                        col4Content = ""
                    }
                }
            } else {
                col4Content = "No Time"
            }

            
        }


        //Assign colour for the colour bar
        const defaultColor = '#FF00FF' // Magenta color for the bar;
        const nationality = this.props.driver.Chassis || this.props.driver.nat;
        const color = this.props.nationalityColors[nationality] || defaultColor;
        

        const containerStyles = {
            display: 'flex',
            alignItems: 'center', // Align items on the cross-axis
            backgroundColor: 'transparent',
            transform: "skew(-14deg)",
            width: (parseInt(this.props.ClientStates.TowerRowWidth)+8)+"px"
          };
            
        const itemStyles = {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: "linear-gradient(90deg, "+posColor+" 0%, "+posColor+" 8.5%, rgb(0, 0, 0) 8.7%, rgb(0, 0, 0) 9.7%, "+this.props.ClientStates.Nbc+" 9.8%, "+this.props.ClientStates.Nbc+" 20%, rgb(0, 0, 0) 20.2%, rgb(0, 0, 0) 21.2%, "+color+" 21.4%, "+color+" 22.5%, rgb(0, 0, 0) 22.7%,rgba(0, 0, 0, 0.90) 61.5%, rgba(0, 0, 0, 0.95) 61.8%, rgba(0, 0, 0, 0.95) 62.3%, rgba(0, 0, 0, 0.85) 62.5%, rgba(0, 0, 0, 0.85) 81.5%, rgba(0, 0, 0, 0.95) 81.6%, rgba(0, 0, 0, 0.95) 82%, rgba(0, 0, 0, 0.80) 82.2%)",
            // backgroundColor: this.props.ClientStates.TowerRowColour, // Dark background
            color: 'white',
            marginLeft: "-4.5px",
            width: (parseInt(this.props.ClientStates.TowerRowWidth)+8)+"px",
            height: this.props.ClientStates.TowerRowHeight
          };
        
          const rankStyles = {
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center',            
            fontFamily: 'Eurostile-bold',
            backgroundColor: "transparent", 
            color: 'white',
            fontSize: '18px', // Smaller than Number
            width: "30px",
            transform: "skew(8deg)",
            marginLeft: "3px",
            filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))"
          };
        
          const numberStyles = {
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            fontFamily: 'Eurostile-it',
            fontWeight: 'bold',
            alignItems: 'center', // Center vertically
            backgroundColor: 'transparent',
            color: 'black',
            fontSize: '18px',
            marginLeft: "0px",
            height: this.props.ClientStates.TowerRowNumberBoardHeight,
            width: "48px", // You've set a fixed width, so make sure it's enough to contain the number
            transform: "skew(14deg)",
            filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))",
        };
        
          const colorBarStyles = {
            width: '4px', // Thin color bar
            backgroundColor: color,
            margin: '2px 0px 2px 2px', // Space around the bar
            height: this.props.ClientStates.TowerRowNumberBoardHeight,
          };

          var fontStretch = '1'
          var font = 'Eurostile-Bold'
          if(competitorName){
            if(competitorName.length > 40){
                fontStretch = '0.35'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 38){
                fontStretch = '0.37'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 36){
                fontStretch = '0.40'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 34){
                fontStretch = '0.43'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 32){
                fontStretch = '0.45'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 30){
                fontStretch = '0.49'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 28){
                fontStretch = '0.54'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 26){
                fontStretch = '0.59'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 24){
                fontStretch = '0.65'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 22){
                fontStretch = '0.72'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 20){
                fontStretch = '0.83'
                font = 'Eurostile-Name'
            } else if(competitorName.length > 18){
                fontStretch = '0.95'
                font = 'Eurostile-Name'
            } else if (competitorName.length > 16){
                font = 'Eurostile-Name'
            }
          }
        
          const nameStyles = {
            flex: 1, // Take up the remaining space
            display: 'flex',
            alignItems: 'center',
            marginLeft: '14px',
            fontFamily: font,
            fontSize: "14px",
            overflowe: 'hidden',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            maxWidth: '140px',
            // transform: "skew(14deg)",
            transform: 'scaleX('+fontStretch+') skew(14deg)',
            transformOrigin: 'left',
            // justifyContent: 'space-between', // Space between the name and the icon
          };
        
          const timeStyles = {
            fontFamily: 'Eurostile-Pro',
            fontWeight: 'regular',
            position: 'absolute',
            width: '65px',
            fontSize: '13px',
            textAlign: 'right',
            marginLeft: '57px',
            lineHeight: '30px',
            transform: "skew(14deg)",
            top: '-6px',
          };
        
          const iconContainerStyles = {
            // display: 'flex',
            position: 'absolute',
            marginTop: '5px',
            width: '140px',
            marginLeft: '250px',
            // justifyContent: 'flex-end',
            // alignItems: 'center'
          };
        
          const iconStyles = {
            height: '20px',
            backgroundColor: 'transparent', // Placeholder for an icon
            transform: "skew(14deg)",
        };

          const spanStyles ={
            marginLeft:this.props.ClientStates.TowerIndent, 
            position: "absolute",  
            top: margin+marginBoost-pagesOffSet+"px", 
            height: height, 
            width: this.props.ClientStates.TowerRowWidth, 
            opacity: 1,
            overflow: "hidden",
          }

          const leftContainerStyles = {
            display: 'flex',
            width: '60%',
            justifyContent: 'flex-start',
            
          }

          const leftTint = {
            position: "absolute",
            // backgroundColor: "grey",
            background: "linear-gradient(180deg, rgba(0, 0, 0,0.12) 0%, rgba(0, 0, 0, 0) 100%)",
            marginTop: ((parseInt(this.props.ClientStates.TowerRowHeight)/2))+"px",
            height:(parseInt(this.props.ClientStates.TowerRowHeight)/2)+"px", 
            width: "91px",
          }
        
          return (
            <div className={"AnimateMargin"} style={spanStyles}>
            {/* <div className={""} style={spanStyles}> */}
              <div style={containerStyles}>  
                <div id="mainRow" style={itemStyles}>
                    <div style={leftTint}></div>

                    <div style={rankStyles}>{this.props.driver.Position}</div>
                
                    <div style={leftContainerStyles}>

                        <div style={numberStyles}>{this.props.driver.CompetitorNumber}</div>
                        {/* <div style={colorBarStyles}></div> */}

                        <div style={nameStyles}>
                            <span>{competitorName.toUpperCase()}</span>
                        </div>

                    </div>

                    
                    <div style={iconContainerStyles}>
                        {/* <img style={iconStyles} src={'https://alphalive.co.uk/TimingGraphics/KC/TeamLogos/RolisonPerformanceGroup.png'} onError={this.props.imageError}></img> */}
                        <img style={iconStyles} src={'https://alphalive.co.uk/TimingGraphics/KC/TeamLogos/'+this.props.driver.TeamName+'.png'} onError={this.props.imageError}></img>
                        <div style={timeStyles}>{col4Content}</div>
                    </div>
                </div>
              </div>
            </div>
          );
        } 

}


 
export default DefaultRow;