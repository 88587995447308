import '../stylesheets/Results.css';
import '../stylesheets/ResultsRow.css';
import React, { PureComponent } from 'react';
import ResultHeader from './resultHeader';
import ResultList from './resultList';


class Results extends PureComponent { 

    render() { 

            if(this.props.Controls.ResultsShow == true){
                var resultsClass = "resultsDiv_show"
            } else {
                var resultsClass = "resultsDiv_hide"
            }
            
            if(this.props.Session.Competitors){
                let indices = [];
                if(this.props.Session.TSLGrouped == 1){
                    var index = this.props.Session.Competitors.findIndex(x => parseInt(x.GroupPosition) == 1)
                } else {
                    indices = this.props.Session.Competitors.filter(x => parseInt(x.Position) === 1);
                    if (indices.length > 1){
                        var winnerIndex = indices.findIndex(x => parseInt(x.NumberOfLaps) != 0)
                        if(winnerIndex > -1){
                            var index = this.props.Session.Competitors.findIndex(x => x.CompetitorNumber == indices[winnerIndex].CompetitorNumber)
                        } else {
                            var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                        }
                    } else {
                        var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                    }
                    
                }





                //BP 24hr Classes
                if (index != -1){
                   var assName = this.props.Session.Competitors[index].CompetitorName.indexOf('* ')
                    if (assName != -1){
                        var compName = this.props.Session.Competitors[index].CompetitorName.replace('* ', '');
                        var star_nbc = "#2faae1"
                        var star_nfc = "#ffffff"
                    } 
                }
                
            } else {
                var index = 0
            }

            if(this.props.Session.State == "ChequeredFlag" || this.props.Session.State == "Ended" ||  this.props.Session.End == true){
                var resultText = "Result"
            } else {
                var resultText = "Current Standings"
            }

            //Country Flags
            if (this.props.ClientStates.CountryFlags == true){
                if (this.props.Session.Competitors[index]){
                    var nationFlag = this.props.Session.Competitors[index].nat 
                    var nationFlagClass = "resultNationFlag"
                } else {
                    var nationFlag = "blank"
                    var nationFlagClass = "resultNationFlag"
                }
                
            } else {
                var nationFlag = "blank"
                var nationFlagClass = "resultNoNationFlag"
            }

            const resultScreenStyles = {
                width: '1582px',
                height: '948px',
                marginTop: '45px',
                marginLeft: '149px',
                color: '#fff',
                fontFamily: 'Arial, sans-serif',
                padding: '20px',
              };
            
            return (
                <div style={resultScreenStyles} className={resultsClass}>
                  <ResultHeader {...this.props} />
                  <ResultList {...this.props} />
                </div>
              );
        }        
    }
export default Results ;