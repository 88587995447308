import React, { PureComponent } from 'react';

class GridHeader  extends PureComponent { 
    render() {

        const gridHeaderStyles = {
            position: 'absolute',
            background: "linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.90) 62.8%, rgba(0, 0, 0, 0.95) 62.9%, rgba(0, 0, 0, 0.95) 63.8%, "+this.props.ClientStates.PrimaryColour+" 64%)",
            height: '108px',
            width: '1000px',
            marginLeft: '-13px',
            transform: 'Skew(-14deg)'
        };

        const logoContainerStyles = {
            backgroundColor: this.props.ClientStates.ThirdColour,
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            width: '487px',
            margin: '3px',
            height: '-webkit-fill-available',
            paddingLeft: "10px"
          };

        const logoStyles = {

        };

        const titleStylesUpper = {
            position: 'absolute',
            marginTop: '18px',
            marginLeft: '700px',
            fontSize: '25px',
            fontFamily: 'Eurostile-WideBoldItalic',
            filter: "drop-shadow(1.5px 1.5px 0px rgba(0,0,0,0.4))"
        };

        const titleStylesLower = {
            position: 'absolute',
            marginTop: '34px',
            marginLeft: '700px',
            fontSize: '50px',
            fontFamily: 'Eurostile-WideBoldItalic',
            filter: "drop-shadow(1.5px 1.5px 0px rgba(0,0,0,0.4))"
        }

        const gridHeaderSpace = {
            overflow: 'hidden',
            position: 'relative',
            alignItems: 'center',
            color: '#000000',
            fontSize: '75px',
            fontFamily: 'Eurostile-Bold',
            fontWeight: 'bold',
            justifyContent: 'center',
            height: '108px',
            width: '896px',
            zIndex:1,
            marginLeft: '535px'
        }

        const raceName = {
            position: 'absolute',
            top: '50%',                           // Start at 50% from the top of the container
            left: '170px',                        // Left position remains as is
            transform: 'translateY(-50%)',        // Shift it up by 50% of its own height to center vertically
            color: 'white',
            fontSize: '30px',
            fontFamily: 'Eurostile-WideBoldItalic',
            width: '450px',
            textAlign: 'center',                  // Ensure the text is centered horizontally
            filter: "drop-shadow(1.5px 1.5px 0px " + this.props.ClientStates.PrimaryColour + ")",
            whiteSpace: 'normal',                 // Allow the text to wrap into multiple lines
            wordWrap: 'break-word',               // Break words if too long
            lineHeight: '1.2',                    // Adjust line height for readability
            height: 'auto',                       // Allow the height to expand for two rows
          };
          

        const resultLogo ={
            position: 'absolute',
            width: '70px',
            marginTop: '20px',
            marginLeft: '45px',

        }

        const bottomTint = {
            position: "absolute",
            background: "linear-gradient(180deg, rgba(0, 0, 0,0.1) 0%, rgba(0, 0, 0, 0) 100%)",
            marginTop: ((parseInt(this.props.ClientStates.TowerHeadHeight)/2)+7)+"px",
            height: '40px', 
            width: "286px",
            marginTop: "68px",
            marginLeft: "616px", 
            transform: "skew(-14deg)",
        }

        return (

            <div style={gridHeaderSpace}>
                <div style={gridHeaderStyles}></div>
                <img style={resultLogo} src={"images/sponsorLogos/KC-White.png"}></img>
                <div style={raceName}>{this.props.selectedRaceName.toUpperCase()}</div>
                <div style={bottomTint}></div>
                <div style={titleStylesUpper}>STARTING</div>
                <div style={titleStylesLower}>GRID</div>
            </div>
            
        );
    }
                //<div style={logoContainerStyles}>
                //    <img src={"images/sponsorLogos/"+this.props.ClientStates.ResultSponsorLogo} style={logoStyles}></img>
                //</div>
                // <div style={titleStylesUpper}>
                //     {this.props.Session.SessionName.toUpperCase()}
                // <div style={titleStylesLower}>UNOFFICIAL RESULTS</div>
                // </div>
}

export default GridHeader ;