import React, { PureComponent } from 'react';
import JumboResultItem from './jumboResultItem';

class JumboResultList  extends PureComponent { 
    render() {

        const resultListStyles = {
            display: 'flex',
            alignItems: 'start',
            padding: 0,
            height: '930px',
            backgroundColor: 'rgb(100,100,100)'
          };
        
        const imageSlotStyles = {
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            backgroundColor: 'transparent',
            marginTop: '10px',
            height: '806px',
            width: '490px',
            overflow: 'hidden',
          };

        const seriesImgStyle = {

        }

        var listContainerHeight = (parseInt(this.props.ClientStates.JumboResultRowHeight)+parseInt(this.props.ClientStates.JumboResultRowSpacing))*parseInt(this.props.ClientStates.JumboResultsFixedPos)

        const resultListDivStyle ={
            position: 'absolute',
            marginLeft: '35px',
            marginTop: '10px',
            overflow: 'hidden',
            backgroundColor: 'transparent',
            height: listContainerHeight+"px",
            width: this.props.ClientStates.JumboResultRowWidth,
        }

        const driverBG = {
            // marginLeft: '200px',
            width: "490px",
        }

        return (
            <div style={resultListStyles}>
                <div style={resultListDivStyle}>
                   {this.props.Session.Competitors.map((driver, index) => (
                    <JumboResultItem key={index} driver={driver} {...this.props}/>
                    ))} 
                </div>
                
          </div>
        )
    }
}

export default JumboResultList ;