import React, { PureComponent } from 'react';
import GridHeader from './gridHeader';
import GridList from './gridList';
import '../stylesheets/Grids.css';
import '../stylesheets/GridsItem.css';

class Grid extends PureComponent { 

    render() { 

            if(this.props.Controls.GridShow == true){
                var gridClass = "gridDiv_show"
                var gridHeight = "100%"
            } else {
                var gridClass = "gridDiv_hide"
                var gridHeight = "0%"
            }
            
            if(this.props.Session.Competitors){
                let indices = [];
                if(this.props.Session.TSLGrouped == 1){
                    var index = this.props.Session.Competitors.findIndex(x => parseInt(x.GroupPosition) == 1)
                } else {
                    indices = this.props.Session.Competitors.filter(x => parseInt(x.Position) === 1);
                    if (indices.length > 1){
                        var winnerIndex = indices.findIndex(x => parseInt(x.NumberOfLaps) != 0)
                        if(winnerIndex > -1){
                            var index = this.props.Session.Competitors.findIndex(x => x.CompetitorNumber == indices[winnerIndex].CompetitorNumber)
                        } else {
                            var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                        }
                    } else {
                        var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                    }
                    
                }

                //BP 24hr Classes
                if (index != -1){
                   var assName = this.props.Session.Competitors[index].CompetitorName.indexOf('* ')
                    if (assName != -1){
                        var compName = this.props.Session.Competitors[index].CompetitorName.replace('* ', '');
                        var star_nbc = "#2faae1"
                        var star_nfc = "#ffffff"
                    } 
                }
                
            } else {
                var index = 0
            }

            if(this.props.Session.State == "ChequeredFlag" || this.props.Session.State == "Ended" ||  this.props.Session.End == true){
                var gridText = "grid"
            } else {
                var gridText = "Current Standings"
            }

            //Country Flags
            if (this.props.ClientStates.CountryFlags == true){
                if (this.props.Session.Competitors[index]){
                    var nationFlag = this.props.Session.Competitors[index].nat 
                    var nationFlagClass = "gridNationFlag"
                } else {
                    var nationFlag = "blank"
                    var nationFlagClass = "gridNationFlag"
                }
                
            } else {
                var nationFlag = "blank"
                var nationFlagClass = "gridNoNationFlag"
            }

            const gridScreenStyles = {
                // width: '1582px',
                marginTop: '57px',
                // marginLeft: '149px',
                color: '#fff',
                // padding: '20px',
              };
            
            return (
                <div style={gridScreenStyles} className={gridClass}>
                    <GridHeader {...this.props} />
                    <GridList {...this.props} />
                </div>
              );
        }        
    }

export default Grid ;