import { transform } from 'lodash';
import React, { PureComponent } from 'react';

class TowerHeader extends PureComponent {

    state = { 
        // rMonitorRaceDuration: 9999,
        rMonitorRaceDuration: 20,
        rMonitorSessionId: null
    }

    render() { 

        //Clock data calculations START
        if (this.props.Session.SD == "rMonitor - Laps"){
            var competitorsState = this.props.Session.Competitors
            var driverIndex = competitorsState.findIndex( x => x.Position == 1)
            if (driverIndex > -1){
                var leaderLaps = competitorsState[driverIndex].NumberOfLaps
            } else {
                var leaderLaps = 0 
            }

            var totalLaps = parseInt(leaderLaps) + parseInt(this.props.timeRemaining)

            // if(leaderLaps == 0){
            //     totalLaps = totalLaps -1
            // }
            
            if (this.state.rMonitorRaceDuration == 9999 || this.state.rMonitorRaceDuration < totalLaps || this.props.Session.SessionId != this.state.rMonitorSessionId){
                this.setState({
                    // rMonitorRaceDuration: totalLaps,
                    rMonitorRaceDuration: 10,
                    rMonitorSessionId: this.props.Session.SessionId
                })
            }
            
            if(leaderLaps == this.props.RaceDuration || this.props.Session.State == "ChequeredFlag"){
                var clockData = "FINISHED"
            } else if (this.props.Session.SD == "rMonitor - Laps" && this.props.timeRemaining == "0"){
                var clockData = "FINISHED"
            } else if (leaderLaps == 0 && this.props.Session.State != "Running"){
                var clockData = "FORMATION"
            } else if (leaderLaps == 0 && this.props.Session.State == "Running"){
                var clockData = "LAP "+ 1 + " / " + this.props.RaceDuration
            } else if (parseInt(leaderLaps)+1 == this.props.RaceDuration ){
                var clockData = "FINAL LAP"
            } else {
                var clockData = "LAP "+ (parseInt(leaderLaps)+1) + " / " + this.props.RaceDuration
            }
            
        } else if (this.props.Session.SD == "rMonitor - Time") {
            if(this.props.Session.State == "ChequeredFlag"){
                var clockData = this.props.timeRemaining
            } else {
                var clockData = this.props.timeRemaining
            }
        } else {
            var addLapIndex = this.props.Session.SD.indexOf("+")
            var lapsIndex = this.props.Session.SD.indexOf("Laps")

            if (this.props.Session.End == true){
                var clockData = "FINISHED"
            } else if (this.props.Session.Last == true) {
                var clockData = "FINAL LAP"
            } else if (this.props.Session.State == "Formation"){
                var clockData = "FORMATION"
                // var clockData = this.props.Session.SD
            } else if (this.props.Session.State == "Starting"){
                var clockData = this.props.timeRemaining
            } else if (lapsIndex > -1 && this.props.ClientStates.TimingSystem != "rMonitor") {
                var remaining = this.props.Session.Rd.replace("L", "")
                var total = parseInt(this.props.Session.SD.replace(" Laps", ""))
                if (this.props.ClientStates.TimingSystem == "Speedhive"){
                    if (remaining != "NAN"){
                        var clockData =   "LAP " + remaining + " / " + total;
                    } else {
                        var clockData =  "";
                    }
                } else {
                    if (remaining != "NAN"){
                        if (parseInt(remaining) == 1){
                            var clockData = "FINAL LAP"
                        } else if (parseInt(remaining) == 0){
                            var clockData = "FINISHED"
                        } else {
                            remaining = total - remaining+1
                            var clockData =  "LAP " + remaining + " / " + total ; 
                        }
                    } else {
                        var clockData =  "";
                    }
                }
            } else if (addLapIndex > -1 && this.props.ClientStates.TimingSystem != "rMonitor"){

                var clockData = this.props.timeRemaining + " +" + this.props.Session.SD[this.props.Session.SD.length-2] + "L"
            } else {
                var clockData = this.props.timeRemaining
            }
        }

        if (this.props.ClientStates.Skin == "Ginetta"){
            var towerHeadClass = "ginettaHead"
        } else {
            var towerHeadClass = "towerHead"
        }

        const towerHeadSponsorStylesUpper = {
            fontFamily:'Eurostile-WideBoldItalic', 
            fontSize:"11px", 
            marginTop: "5px",
            height:(parseInt(this.props.ClientStates.TowerHeadHeight)/2)+"px", 
            width: (parseInt(this.props.ClientStates.TowerHeadWidth) - parseInt(this.props.ClientStates.TowerSponsorWidth)-5) + "px", 
            textAlign: "center",marginLeft: this.props.ClientStates.TowerSponsorWidth, 
            color: this.props.ClientStates.ThirdColour,
            // transform: "skew(-10deg)",
            filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))"
        }

        const towerHeadSponsorStylesBottom = {
            fontFamily:'Eurostile-Clock', 
            fontSize:"22px", 
            paddingTop: "0px", 
            marginTop: ((parseInt(this.props.ClientStates.TowerHeadHeight)/2)-7)+"px",
            height:(parseInt(this.props.ClientStates.TowerHeadHeight)/2)+"px", 
            width: (parseInt(this.props.ClientStates.TowerHeadWidth) - parseInt(this.props.ClientStates.TowerSponsorWidth)-5) + "px", 
            textAlign: "center",
            marginLeft: this.props.ClientStates.TowerSponsorWidth, 
            color: this.props.ClientStates.ThirdColour,
            // transform: "skew(-10deg)",
            filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))"
        }

        var raceStateColour = this.props.raceStateColour || this.props.ClientStates.PrimaryColour

        const sponsorBG = {
            background: "linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.90) 62.8%, rgba(0, 0, 0, 0.95) 62.9%, rgba(0, 0, 0, 0.95) 63.8%, "+raceStateColour+" 64%)",
            height:this.props.ClientStates.TowerSponsorHeight, 
            width: parseInt(this.props.ClientStates.TowerHeadWidth)+40+"px",
            overflow: "hidden",
            position: "relative",
            opacity: 1,
            float: "left",
            transform: "skew(-14deg)",
            marginLeft: "-20px"
        }

        const towerHeadStyles = {
            marginLeft:this.props.ClientStates.TowerIndent,
            height:this.props.ClientStates.TowerHeadHeight, 
            width:parseInt(this.props.ClientStates.TowerHeadWidth)+"px", 
            backgroundColor: "transparent"
        }

        const sponsorLogo = {
            height: "30px",
            marginLeft: "40px",
            marginTop: "8px",
            transform: "skew(14deg)", //unskews from the parent div
        }

        const bottomTint = {
            position: "absolute",
            background: "linear-gradient(180deg, rgba(0, 0, 0,0.1) 0%, rgba(0, 0, 0, 0) 100%)",
            marginTop: ((parseInt(this.props.ClientStates.TowerHeadHeight)/2)+7)+"px",
            height:(parseInt(this.props.ClientStates.TowerHeadHeight)/2)+"px", 
            width: (parseInt(this.props.ClientStates.TowerHeadWidth) - parseInt(this.props.ClientStates.TowerSponsorWidth)) + "px",
            marginLeft: (parseInt(this.props.ClientStates.TowerSponsorWidth))+"px", 
            transform: "skew(-14deg)",
        }

        if (this.props.ClientStates.TowerSponsor == true){
            return ( 
                <div className={towerHeadClass} style={towerHeadStyles}>
                    <div style={sponsorBG}>
                        <img className='SponsorLogoShow' src={"images/sponsorLogos/"+this.props.ClientStates.TowerSponsorLogo} style={sponsorLogo} onError={this.props.imageError}></img>
                    </div>
                    {/* <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesUpper}>{this.props.Session.SessionName.toUpperCase()}</div> */}
                    <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesUpper}>{this.props.TickerName}</div>
                    <div style={bottomTint}></div>
                    {/* <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesBottom}>00:00:00</div> */}
                    <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesBottom}>{clockData}</div>
                </div>
            );
        } else {
            return ( 
                <div className={towerHeadClass} style={{height:"35px"}}>
                    <img className='SponsorLogoHide' src={"images/sponsorLogos/"+this.props.ClientStates.TowerSponsorLogo} style={{height:this.props.ClientStates.TowerSponsorSize, width: "0px"}}></img>
                    <div className='TowerHeadText' style={{height:"35px"}}>{clockData}</div>
                </div>
            );
        }        
    }


}
export default TowerHeader ;
