import React, { Component } from 'react';
import JumboHeader from './Graphics/jumboHeader';
import JumboTower from './Graphics/jumboTower';
import JumboResults from './Graphics/Results/jumboResultsView';
import JumboGrid from './Graphics/Grids/jumboGridsView';

class Jumbo extends Component {

    render() { 
        if (this.props.Session.Competitors === undefined) {
            return (
                <div></div>
            );

        } else {
            //Tower Show Setup
            if (this.props.Controls.TowerShow == true){
                var towerClass = "show"
            } else {
                var towerClass = "hide"
            }
            
            //Session State Setup
            if (this.props.Session.fcy == true){
                var StateBarColour = this.props.ClientStates.Yellow
                var towerClass = "show"
            } else if (this.props.Session.State == "Running"){
                var StateBarColour = this.props.ClientStates.Green
                var towerClass = "show"
            } else if (this.props.Session.State == "RedFlag"){
                var StateBarColour = this.props.ClientStates.Red
                var towerClass = "show"
            } else if (this.props.Session.State == "ChequeredFlag" && this.props.ClientStates.ChequeredFlag == true){
                // var StateBarColour = "ChequeredFlag"
                var StateBarColour = this.props.ClientStates.PrimaryColour
                var towerClass = "show"
            } else if (this.props.Session.State == "Ended" || this.props.Session.State == "Complete" && this.props.ClientStates.ChequeredFlag == true){
                // var StateBarColour = "ChequeredFlag"
                var StateBarColour = this.props.ClientStates.PrimaryColour
                var towerClass = "hide"
            } else if (this.props.Session.SD == "rMonitor - Laps" && this.props.timeRemaining == "0" && this.props.ClientStates.ChequeredFlag == true){
                // var StateBarColour = "ChequeredFlag"
                var StateBarColour = this.props.ClientStates.PrimaryColour
                var towerClass = "show"
            } else {
                var StateBarColour = this.props.ClientStates.PrimaryColour
                var towerClass = "hide"
            }

            if (this.props.Controls.ClockShow == false){
                var towerClass = "hide_clock"
            } else {
                var towerClass = "show_clock"
            }


                return ( 
                <div className="App">
                    <JumboResults {...this.props}/>
                    <JumboGrid {...this.props}/>
                    <div className={towerClass}>
                    <JumboHeader raceStateColour={StateBarColour} {...this.props}/>
                    <JumboTower {...this.props}/>  
                    </div>
                
                </div>
            );
            
            
        }        
    }
}
export default Jumbo ;
