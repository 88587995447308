import React, { PureComponent } from 'react';
import ResultItem from './resultItem';

class ResultList  extends PureComponent { 
    render() {

        const resultListStyles = {
            display: 'flex',
            alignItems: 'start',
            padding: 0,
            height: '825px',
          };
        
        const imageSlotStyles = {
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            backgroundColor: 'transparent',
            marginTop: '10px',
            height: '806px',
            width: '490px',
            overflow: 'hidden',
          };

        const seriesImgStyle = {

        }

        var listContainerHeight = (parseInt(this.props.ClientStates.ResultRowHeight)+parseInt(this.props.ClientStates.ResultRowSpacing))*parseInt(this.props.ClientStates.ResultsFixedPos)

        const resultListDivStyle ={
            position: 'absolute',
            marginLeft: '500px',
            marginTop: '10px',
            overflow: 'hidden',
            backgroundColor: 'transparent',
            height: listContainerHeight+"px",
            width: this.props.ClientStates.ResultRowWidth,
        }

        const driverBG = {
            // marginLeft: '200px',
            width: "490px",
        }

        return (
            <div style={resultListStyles}>
                <div style={imageSlotStyles}>
                    <img src={"images/KC-DriverBG.png"} style={driverBG}/>
                    {/* <img src={"images/sponsorLogos/"+this.props.ClientStates.ResultSeriesLogo} style={seriesImgStyle} onError={this.props.imageError}></img> */}
                </div>
                <div style={resultListDivStyle}>
                   {this.props.Session.Competitors.map((driver, index) => (
                    <ResultItem key={index} driver={driver} {...this.props}/>
                    ))} 
                </div>
                
          </div>
        )
    }
}

export default ResultList ;