import { transform } from 'lodash';
import React, { PureComponent } from 'react';

class JumboHeader extends PureComponent {

    state = { 
        // rMonitorRaceDuration: 9999,
        rMonitorRaceDuration: 20,
        rMonitorSessionId: null
    }

    render() { 

        //Clock data calculations START
        if (this.props.Session.SD == "rMonitor - Laps"){
            var competitorsState = this.props.Session.Competitors
            var driverIndex = competitorsState.findIndex( x => x.Position == 1)
            if (driverIndex > -1){
                var leaderLaps = competitorsState[driverIndex].NumberOfLaps
            } else {
                var leaderLaps = 0 
            }

            var totalLaps = parseInt(leaderLaps) + parseInt(this.props.timeRemaining)

            // if(leaderLaps == 0){
            //     totalLaps = totalLaps -1
            // }
            
            if (this.state.rMonitorRaceDuration == 9999 || this.state.rMonitorRaceDuration < totalLaps || this.props.Session.SessionId != this.state.rMonitorSessionId){
                this.setState({
                    // rMonitorRaceDuration: totalLaps,
                    rMonitorRaceDuration: 10,
                    rMonitorSessionId: this.props.Session.SessionId
                })
            }
            var fontSize = "35px"
            if(leaderLaps == this.props.RaceDuration || this.props.Session.State == "ChequeredFlag"){
                var clockData = "FINISHED"
                var fontSize = "20px"
            } else if (this.props.Session.SD == "rMonitor - Laps" && this.props.timeRemaining == "0"){
                var clockData = "FINISHED"
                var fontSize = "20px"
            } else if (leaderLaps == 0 && this.props.Session.State != "Running"){
                var clockData = "FORMATION"
                var fontSize = "20px"
            } else if (leaderLaps == 0 && this.props.Session.State == "Running"){
                var clockData = 1 + " / " + this.props.RaceDuration
            } else if (parseInt(leaderLaps)+1 == this.props.RaceDuration ){
                var clockData = "FINAL LAP"
                var fontSize = "20px"
            } else {
                var clockData = (parseInt(leaderLaps)+1) + " / " + this.props.RaceDuration
            }
            
        } else if (this.props.Session.SD == "rMonitor - Time") {
            if(this.props.Session.State == "ChequeredFlag"){
                var clockData = this.props.timeRemaining
            } else {
                var clockData = this.props.timeRemaining
                if(clockData.length == 8){
                    clockData = clockData.substring(3)
                } else if (clockData.length == 7){
                    clockData = clockData.substring(2)
                }
            }
        } else {
            var addLapIndex = this.props.Session.SD.indexOf("+")
            var lapsIndex = this.props.Session.SD.indexOf("Laps")

            if (this.props.Session.End == true){
                var clockData = "FINISHED"
            } else if (this.props.Session.Last == true) {
                var clockData = "FINAL LAP"
            } else if (this.props.Session.State == "Formation"){
                var clockData = "FORMATION"
                // var clockData = this.props.Session.SD
            } else if (this.props.Session.State == "Starting"){
                var clockData = this.props.timeRemaining
            } else if (lapsIndex > -1 && this.props.ClientStates.TimingSystem != "rMonitor") {
                var remaining = this.props.Session.Rd.replace("L", "")
                var total = parseInt(this.props.Session.SD.replace(" Laps", ""))
                if (this.props.ClientStates.TimingSystem == "Speedhive"){
                    if (remaining != "NAN"){
                        var clockData =   remaining + " / " + total;
                    } else {
                        var clockData =  "";
                    }
                } else {
                    if (remaining != "NAN"){
                        if (parseInt(remaining) == 1){
                            var clockData = "FINAL LAP"
                        } else if (parseInt(remaining) == 0){
                            var clockData = "FINISHED"
                        } else {
                            remaining = total - remaining+1
                            var clockData =  remaining + " / " + total ; 
                        }
                    } else {
                        var clockData =  "";
                    }
                }
            } else if (addLapIndex > -1 && this.props.ClientStates.TimingSystem != "rMonitor"){

                var clockData = this.props.timeRemaining + " +" + this.props.Session.SD[this.props.Session.SD.length-2] + "L"
            } else {
                var clockData = this.props.timeRemaining
            }
        }

        if (this.props.ClientStates.Skin == "Ginetta"){
            var towerHeadClass = "ginettaHead"
        } else {
            var towerHeadClass = "towerHead"
        }

        const towerHeadSponsorStylesUpper = {
            fontFamily:'Eurostile-Bold', 
            fontSize:"28px", 
            marginTop: "0px",
            height:(parseInt(this.props.ClientStates.JumboTowerHeadHeight)/2)+"px", 
            width: "276px", 
            overflow: "hidden",
            textAlign: "center", 
        }

        const towerHeadSponsorStylesBottom = {
            position: 'absolute',
            fontFamily:'Eurostile-Bold', 
            fontSize: fontSize, 
            height:(parseInt(this.props.ClientStates.JumboTowerHeadHeight)/2)+"px", 
            width: (parseInt(this.props.ClientStates.JumboTowerHeadWidth) - parseInt(towerHeadSponsorStylesUpper.width) + 15) + "px", 
            textAlign: "center",
            marginTop: '0px',
            lineHeight: ((parseInt(this.props.ClientStates.JumboTowerHeadHeight)/2)-3)+'px',
            marginLeft: (parseInt(towerHeadSponsorStylesUpper.width)+0) + "px", 
            color: this.props.ClientStates.ThirdColour,
        }

        var raceStateColour = this.props.raceStateColour || this.props.ClientStates.PrimaryColour

        const sponsorBG = {
            background: "linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0) 62.8%, rgba(0, 0, 0) 62.9%, rgba(0, 0, 0) 63.8%, "+raceStateColour+" 64%)",
            height:this.props.ClientStates.JumboTowerSponsorHeight, 
            width: parseInt(this.props.ClientStates.JumboTowerHeadWidth)+40+"px",
            overflow: "hidden",
            position: "relative",
            opacity: 1,
            float: "left",
            marginLeft: "-20px"
        }

        const towerHeadStyles = {
            marginLeft:this.props.ClientStates.JumboTowerIndent,
            height:this.props.ClientStates.JumboTowerHeadHeight, 
            width:parseInt(this.props.ClientStates.JumboTowerHeadWidth)+"px", 
            backgroundColor: "transparent",
            marginTop: '0px',
        }

        const sponsorLogo = {
            height: (parseInt(this.props.ClientStates.JumboTowerHeadHeight)/2)+"px",
            marginLeft: "0px",
            marginTop: "0px",
            backgroundColor: 'white',
            width: this.props.ClientStates.JumboTowerHeadWidth
        }

        const bottomTint = {
            position: "absolute",
            background: "linear-gradient(180deg, rgba(0, 0, 0,0.1) 0%, rgba(0, 0, 0, 0) 100%)",
            marginTop: ((parseInt(this.props.ClientStates.JumboTowerHeadHeight)/2)+7)+"px",
            height:(parseInt(this.props.ClientStates.JumboTowerHeadHeight)/2)+"px", 
            width: (parseInt(this.props.ClientStates.JumboTowerHeadWidth) - parseInt(this.props.ClientStates.JumboTowerSponsorWidth)) + "px",
            marginLeft: (parseInt(this.props.ClientStates.JumboTowerSponsorWidth))+"px", 
            transform: "skew(-14deg)",
        }


        return ( 
            <div className={towerHeadClass} style={towerHeadStyles}>
                <div style={sponsorBG}>
                    <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesUpper}>{this.props.TickerName}</div>
                    <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesBottom}>{clockData}</div>
                </div>
                {/* <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesUpper}>{this.props.Session.SessionName.toUpperCase()}</div> */}
                <img className='SponsorLogoShow' src={"images/sponsorLogos/"+this.props.ClientStates.JumboTowerSponsorLogo} style={sponsorLogo} onError={this.props.imageError}></img>
                {/* <div style={bottomTint}></div> */}
                {/* <div className='TowerHeadTextSponsor' style={towerHeadSponsorStylesBottom}>00:00:00</div> */}
                
            </div>
        );
 
    }


}
export default JumboHeader ;
