import React, { PureComponent } from 'react';

class JumboResultItem  extends PureComponent { 
    render() {


        //Opacity Calculations START
        if (this.props.driver.NumberOfLaps < 1){
            var opacity = 0
        } else if (this.props.driver.seen == false) {
            var opacity = 0
        } else {
            var opacity = 1
        }
        
        if (!this.props.driver.Position){
            opacity = 0
        }
        //Opacity Calculations END

        //Smart Naming Calculations START
        if(this.props.driver.CompetitorName){
            var assName = this.props.driver.CompetitorName.indexOf('* ')
            if (assName != -1){
                var compName = this.props.driver.CompetitorName.replace('* ', '');
                var star_nbc = "#2faae1"
                var star_nfc = "#ffffff"
            }
            if (this.props.driver.CompetitorName){
                var competitorName = compName || this.props.driver.CompetitorName
                var run = 0
                while (run == 0){
                    var nameArray = competitorName.split(",").join(" ").split(" ")
                    if (run > 0){
                        var nameArray = competitorName.split("-").join(" ").split(" ")
                        var nameStart = nameArray[0]
                        var firstName = nameStart.substring(0,2*run+1)
                    } else {
                        var firstName = nameArray[0][0]
                        var nameStart = nameArray[0]
                    }
                    nameArray.shift()
                    var competitorName = firstName+". "+nameArray
                    var remainingName = " " + nameArray
                    run++
                    competitorName = competitorName.replace(/,/g, ' ');
                    remainingName = remainingName.replace(/,/g, ' ');
                    if (run > 5){ break }
                }

            } else {
                var competitorName = compName || this.props.driver.CompetitorName
            }
        } else {
            var competitorName = "undefined"
        }
        //Smart Naming Calculations END

        //Assign colour for the colour bar
        const defaultColor = '#FF00FF' // Magenta color for the bar;
        const nationality = this.props.driver.Chassis || this.props.driver.nat;
        const color = this.props.nationalityColors[nationality] || defaultColor;
        
        const containerStyles = {
          display: 'flex',
          alignItems: 'center', // Align items on the cross-axis
          backgroundColor: 'transparent',
          transform: "skew(-14deg)",
          width: (parseInt(this.props.ClientStates.JumboResultRowWidth)+10)+"px"
        };
          
      const itemStyles = {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          background: "linear-gradient(90deg, "+this.props.ClientStates.PrimaryColour+" 0%, "+this.props.ClientStates.PrimaryColour+" 6%, rgb(0, 0, 0) 6.1%, rgb(0, 0, 0) 7%, "+this.props.ClientStates.Nbc+" 7.1%, "+this.props.ClientStates.Nbc+" 15.5%, rgb(0, 0, 0) 15.6%, rgb(0, 0, 0) 16.5%, "+color+" 16.6%, "+color+" 17.5%, rgb(0, 0, 0) 17.6%,rgba(0, 0, 0) 70.8%, rgba(0, 0, 0, 0) 70.9%, rgba(0, 0, 0, 0) 71.4%, rgba(0, 0, 0) 71.5%, rgba(0, 0, 0) 81.0%, rgba(0, 0, 0, 0) 81.1%, rgba(0, 0, 0, 0) 81.5%, rgba(0, 0, 0) 81.6%)",
          // backgroundColor: this.props.ClientStates.TowerRowColour, // Dark background
          color: 'white',
          marginLeft: "-8px",
          width: (parseInt(this.props.ClientStates.JumboResultRowWidth)+50)+"px",
          height: this.props.ClientStates.JumboResultRowHeight
        };
      
        const rankStyles = {
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          alignItems: 'center',            
          fontFamily: 'Eurostile-bold',
          backgroundColor: "transparent", 
          color: 'white',
          fontSize: '65px', // Smaller than Number
          width: "100px",
          transform: "skew(8deg)",
          marginLeft: "10px",
          lineHeight: this.props.ClientStates.JumboResultRowHeight,
          filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))"
        };
      
        const numberStyles = {
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          fontFamily: 'Eurostile-it',
          fontWeight: 'bold',
          alignItems: 'center', // Center vertically
          backgroundColor: 'transparent',
          color: 'black',
          fontSize: '68px',
          marginLeft: "50px",
          lineHeight: this.props.ClientStates.ResultRowHeight,
          width: "100px", // You've set a fixed width, so make sure it's enough to contain the number
          transform: "skew(14deg)",
          filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))",
      };
      
        const nameStyles = {
          flex: 1, // Take up the remaining space
          display: 'flex',
          alignItems: 'center',
          marginLeft: '88px',
          transform: "skew(14deg)",
          height:  this.props.ClientStates.JumboResultRowHeight,
          lineHeight: this.props.ClientStates.JumboResultRowHeight,
          // justifyContent: 'space-between', // Space between the name and the icon
        };

        const nameStartStyle = {
          marginRight: '15px',
          fontFamily: 'Eurostile',
          fontSize: '55px',
          letterSpacing: '0.07em',
          textTransform: 'uppercase',
        }

        const nameEndStyle = {
          marginRight: '8px',
          fontSize: '55px',
          fontFamily: 'Eurostile-Bold',
          letterSpacing: '0.07em',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap'
        }
      
      //   const timeStyles = {
      //     fontFamily: 'Eurostile-Bold',
      //     fontWeight: 'regular',
      //     width: '85px',
      //     fontSize: '20px',
      //     textAlign: 'right',
      //     marginRight: '30px',
      //     lineHeight: this.props.ClientStates.ResultRowHeight,
      //     transform: "skew(14deg)",
      //   };
      
      //   const iconContainerStyles = {
      //     display: 'flex',
      //     width: '40%',
      //     justifyContent: 'flex-end',
      //     alignItems: 'center'
      //   };
      
      //   const iconStyles = {
      //     height: '41px',
      //     marginRight: '50px',
      //     backgroundColor: 'transparent', // Placeholder for an icon
      //     transform: "skew(14deg)",
      // };

      const timeStyles = {
        fontFamily: 'Eurostile-Bold',
        fontWeight: 'regular',
        position: 'absolute',
        width: '275px',
        fontSize: '50px',
        textAlign: 'right',
        marginLeft: '190px',
        lineHeight: '30px',
        transform: "skew(14deg)",
        lineHeight: this.props.ClientStates.JumboResultRowHeight,
        top: '-20px',
        letterSpacing: '0.05em'
      };
    
      const iconContainerStyles = {
        // display: 'flex',
        position: 'absolute',
        marginTop: '5px',
        width: '140px',
        marginLeft: '1370px',
        // justifyContent: 'flex-end',
        // alignItems: 'center'
      };
    
      const iconStyles = {
        height: '40px',
        backgroundColor: 'transparent', // Placeholder for an icon
        transform: "skew(14deg)",
    };

      var marginTop = (parseInt(this.props.ClientStates.JumboResultRowHeight) + parseInt(this.props.ClientStates.JumboResultRowSpacing)) * parseInt(this.props.driver.Position-1) 
          marginTop = marginTop + this.props.Controls.JumboResultsMargin
          

        const spanStyles ={
          marginLeft:'-2px', 
          position: "absolute",  
          top: marginTop +"px",
          height: this.props.ClientStates.JumboResultRowHeight,
          width: this.props.ClientStates.JumboResultRowWidth,
          opacity: 1,
          overflow: "hidden",
        }

        const leftContainerStyles = {
          display: 'flex',
          width: '60%',
          justifyContent: 'flex-start',
          
        }

        const leftTint = {
          position: "absolute",
          // backgroundColor: "grey",
          background: "linear-gradient(180deg, rgba(0, 0, 0,0.12) 0%, rgba(0, 0, 0, 0) 100%)",
          marginTop: ((parseInt(this.props.ClientStates.JumboResultRowHeight)/2))+"px",
          height:(parseInt(this.props.ClientStates.JumboResultRowHeight)/2)+"px", 
          width: "336px",
        }
        

        //If Qualifying Session - Calculate the gap to the leader to display in col4 content
        var gap = this.props.driver.Behind
        if (this.props.Session.SessionType == 'Qual'){

          if(this.props.driver.BestLaptime){
              if (parseInt(this.props.driver.Position) == 1){
                gap = this.props.msToTime(this.props.driver.BestLaptime*1000)
              } else {
                var p1Index = this.props.Session.Competitors.findIndex(x => x.Position == 1)
                
                if(p1Index != - 1){
                  var p1Best = this.props.Session.Competitors[p1Index].BestLaptime*1000
                  var driverBest = this.props.driver.BestLaptime*1000

                  var difference =  driverBest - p1Best

                  gap = "+" + this.props.msToTime(difference)
                } else {
                  gap = ""
                }
              }
          } else {
            gap = "No Time"
          }

          
        } else {
          if (parseInt(this.props.driver.Position) != 1){
            gap = "+"+gap
          }
        }
        
          return (
            <div className={"AnimateMargin"} style={spanStyles}>
            <div style={containerStyles}>  
              <div id="mainRow" style={itemStyles}>
                  <div style={leftTint}></div>

                  <div style={rankStyles}>{this.props.driver.Position}</div>
              
                  <div style={leftContainerStyles}>

                      <div style={numberStyles}>{this.props.driver.CompetitorNumber}</div>
                      {/* <div style={colorBarStyles}></div> */}

                      <div style={nameStyles}>
                          <span style={nameStartStyle}>{nameStart.toUpperCase()}</span><span style={nameEndStyle}>{remainingName.toUpperCase()}</span>
                          {/* <span style={nameEndStyle}>{this.props.driver.TeamName || remainingName}</span> */}
                      </div>

                  </div>

                  
                  <div style={iconContainerStyles}>
                      {/* <img style={iconStyles} src={'https://alphalive.co.uk/TimingGraphics/KC/TeamLogos/RolisonPerformanceGroup.png'} onError={this.props.imageError}></img> */}
                      <img style={iconStyles} src={'https://alphalive.co.uk/TimingGraphics/KC/TeamLogos/'+this.props.driver.TeamName+'.png'} onError={this.props.imageError}></img>
                      <div style={timeStyles}>{gap}</div>
                  </div>
              </div>
            </div>
          </div>
          );
    }
}

export default JumboResultItem ;