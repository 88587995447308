import React, { Component, useEffect} from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import io from "socket.io-client";
import Pusher from 'pusher-js';


import ControllerPage from "./components/controller.jsx"
import Graphics from './components/graphics';
import Jumbo from './components/jumbo';
import Table from './components/table.jsx'

const request = require('request')

//taken from alphatiming
const alphaPusher = {
    app_id: '46792',
    key: '3aaffebc8193ea83cb2f',
    secret: '9e231b9a9966903ef64a',
    host: "eu"

}
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let socketIP = "http://77.68.119.159:3033"
//let socketIP = "https://kc-socket-422020.uc.r.appspot.com"
let series = params.series;
let vmix = params.vmix;
let alpha = params.alpha;
let red = params.red;
const room = series

const socket = io.connect(socketIP);
socket.emit('join_room', series)

console.log(series,vmix,alpha)

class App extends Component {
  state = { 
    Controls: {
      AutoCol: false,
      IncludePs: false,
      ClockShow: true,
      TowerShow: true,
      ResultsShow: false,
      GridShow: false,
      NotificationShow: false,
      FastestLapShow: false,
      ChampionshipShow: true,
      ChampionshipSelect: 0,
      ComparisonShow: false,
      ComparisonLeadDriver: undefined,
      TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
      ResultsMargin: 0,
      JumboResultsMargin: 0,
      GridsMargin: 20,
      JumboGridsMargin: 0,
      TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
      CurrentPage: 1,
      BattleShow: false,
      BattleDrivers: [],
      BattlePosition: undefined,
      ProgressionShow: false,
      ProgressionPosition: undefined,
      Col4: "gap",
      Expand: false,
      ExpandDriverID: undefined,
      LiveChampsTitle: "LIVE CHAMPIONSHIP",
      LiveChampsDisplay: "liveChampHide",
    },
    Session:  {
    },
    ClientStates: {
      TimingName: "KC",
      TimingSystem: "rMonitor",
      DriverPhotos: false,
      FlSponsor: false,
      TowerSponsor: true,
      TowerHeadHeight: "118px",
      TowerHeadWidth: "375px",
      TowerSponsorHeight: "38px",
      TowerSponsorWidth: "250px",
      TowerSponsorLogo: "KC-TopperLogo.png",
      JumboTowerSponsor: true,
      JumboTowerHeadHeight: "88px",
      JumboTowerHeadWidth: "400px",
      JumboTowerSponsorLogo: "skusaJUMBO.png",
      JumboTowerSponsorHeight: "46px",
      JumboTowerSponsorWidth: "250px",
      JumboTowerGapToHeader: "0px",
      ResultSponsorLogo: "KCResultsLogo.png",
      ResultSeriesLogo: "KCResultsLogoLarge.png",
      JumboResultSponsorLogo: "KCResultsLogo.png",
      JumboResultSeriesLogo: "KCResultsLogoLarge.png",
      JumboResultRowHeight:"82px",
      JumboResultRowSpacing: "8px",
      JumboResultRowWidth: "1850px",
      JumboResultRowNumberBoardHeight: "82px",
      JumboResultRowColour: "rgb(0 0 0 / 65%)",
      JumboResultsFixedPos: 10,
      PrimaryColour: "#00aeef",
      SecondaryColour: "#ffffff",
      ThirdColour: "#000000",
      ResultsSponsor: false,
      CountryFlags: false,
      VehicleLogos: false,
      DefaultProgression: 4,
      Nbc: "#feff0c",
      Nfc: "#000000",
      Yellow:"#ffe011",
      Red:"#ff0000",
      Green:"#19c113",
      FastestLap:"#ff00ff",
      CheckeredFlag:false,
      TowerGapToHeader: "4px",
      ResultRowHeight:"50px",
      ResultRowSpacing: "4px",
      ResultRowWidth: "1084px",
      ResultRowNumberBoardHeight: "44px",
      ResultRowColour: "rgb(0 0 0 / 65%)",
      ResultsFixedPos: 15,
      GridRowHeight:"48px",
      GridRowWidth: "830px",
      GridRowSpacing:"20px",
      GridVisibleRows: 10,
      GridRowColour: "rgb(0 0 0 / 75%)",
      JumboGridRowHeight:"72px",
      JumboGridRowWidth: "854px",
      JumboGridRowSpacing:"5px",
      JumboGridVisibleRows: 12,
      JumboGridRowColour: "rgb(0 0 0 / 75%)",
      TowerRowHeight:"30px",
      TowerRowSpacing:"3px",
      TowerRowWidth:"375px",
      TowerRowNumberBoardHeight: "30px",
      TowerFixedPos:10,
      TowerTotalPos:20,
      TowerRowColour: "rgb(0 0 0 / 65%)", //65% transparency
      JumboTowerRowHeight:"65px",
      JumboTowerRowSpacing:"1px",
      JumboTowerRowWidth:"442px",
      JumboTowerRowNumberBoardHeight: "63px",
      JumboTowerFixedPos:15,
      JumboTowerTotalPos:15,
      JumboTowerIndent: "0px",
      DividingLine:false,
      TowerIndent: "30px",
      PageRotateSpeed: 15000
    },
    Notifications: [],
    NotificationSequence: undefined,
    NotificationSelected: 0,
    TickerName: "",
    RaceDuration: 20,
    Headers: [
        {
            name: 'X30-Junior',
            file: 'X30-Junior'
        },{
            name: 'Pro-X30',
            file: 'Pro-X30'
        },{
            name: 'Pro-Shifter',
            file: 'Pro-Shifter'
        },{
            name: 'Mini-Swift',
            file: 'Mini-Swift'
        },{
            name: 'Micro-Swift',
            file: 'Micro-Swift'
        },{
            name: 'Master-Shifter',
            file: 'Master-Shifter'
        },{
            name: 'KA-Senior',
            file: 'KA-Senior'
        },{
            name: 'KA-Junior',
            file: 'KA-Junior'
        },{
            name: 'SKUSA',
            file: 'SKUSA'
        }
    ],
    nationalityColors: {
        'BestKart': '#131524',
        'Factory Kart': '#383939',
        'Tony Kart': '#009945',
        'Kart Republic': '#ffffff',
        'Sodi Kart': '#ff571f',
        'Kosmic': '#eb0080',
        'Exprit': '#000000',
        'CRG': '#bdae00',
        'CROC Promotion': '#006ba0',
        'GFC': '#000000',
        'Magik Kart': '#00aae1',
        'BirelART': '#fe0120',
        'Parolin': '#f52300',
        'CompKart': '#fff400',
        'LN Kart': '#22205f',
        'RedSpeed': '#af3f41',
        'Italkart': '#818286',
        'GP Kart': '#c8c8e0',
        'Aluminos': '#747577',
        'Energy': '#5d8cf8',
        'Maranello': '#fe0002',
        'Merlin': '#12c63e',
        'Alonso Kart': '#1dace2',
        'Tecno': '#ec2028',
        'TB Kart': '#02ff00',
        'Benik': '#0089cf',
        'KF KART': '#f23d34',
        'EOS Kart': '#1dace2',
        'Falcon Kart': '#02e8db',
        'Nitro Kart': '#df1d16',
        'Gillard': '#fa362a',
		'Pantano': '#fff200',
      },
    meetings:[
        {
            "date":"N/A",
            "hide":false,
            "name":"Click get button to get list of events",
            "number": null
        }
      ],
    races:[
        {
            bestLap: null,
            classes: [],
            dp: 3,
            id: "",
            name: "Please Select Meeting",
            numberOfLaps: "",
            sessionType: "",
            startTime: "",
            status: "",
            tsid: 1,
            winnersName: ""
        }
    ],
    selectedRace: undefined,
    selectedRaceName: "TEST",
    selectedHeader: series,
    grid:[
        {
            cid: "92624",
            number: {
                number: "157",
                numberBackgroundColour: "#FF0000",
                numberForegroundColour: "#FFFFFF"
            },
            name: "William ANTROBUS",
            cls: "JM",
            subClass: null,
            gridPosition: 1,
            qualification: "1A [1st in Q2 46.56]",
            Chassis: null,
            Engine: null,
            TeamName: null
        }
    ],
    
  }


    componentDidMount() {

    if(red == "true"){
        var clientStates = this.state.ClientStates
        clientStates.PrimaryColour = "#ec2027"
        this.setState({
            ClientStates: clientStates
        })
    }

    //Turn on page rotate
    setInterval(() => this.pages(), this.state.ClientStates.PageRotateSpeed);

    var lastMessage = ""

    //THIS IS WHERE THE SOCKET.IO MESSAGES COME IN
    socket.on("receive_message", (data) => {
        if (JSON.stringify(lastMessage) != JSON.stringify(data.message)|| data.message == "getGrid" || data.message == "getMeetings" || data.type == "Graphics"){
        lastMessage = { ...data.message }; // Store a copy of the object

        console.log(data.message)
        var controls = this.state.Controls
        var session = this.state.Session
        // console.log(data.author)

        

        if (data.author == "Server"){
            var messageJSON = JSON.parse(data.message)
             console.log(data.type, messageJSON)
            if (this.state.ClientStates.TimingSystem == "TSL"){
                switch(data.type){
                case "DATA ActiveSession":
                    console.log("ActiveSession")
                    console.log(JSON.parse(data.message))
                    if (messageJSON.LengthTime && messageJSON.LengthTime != "0.000"){
                        var sd = this.msRacetime(messageJSON.LengthTime/1000)
                    }

                    if (messageJSON.LengthLaps){
                        var sdLaps = messageJSON.LengthLaps
                        if (sd){
                            sd = sd + " + " + sdLaps + "L"
                        } else {
                            var sd = sdLaps+"Laps"
                            var timeRemaining = messageJSON.LapsRemaining + "L" || "0"
                        }
                    }

                    if (messageJSON.State){
                        switch(messageJSON.State){
                            case "Pending":
                                var raceState = "Formation"
                                break
                            case "Active":
                                var raceState = "Formation"
                                break
                            case "Green":
                                var raceState = "Running"
                                break
                            case "Yellow":
                                var raceState = "Running"
                                var fcy = true
                                break
                            case "FCY":
                                var raceState = "Running"
                                var fcy = true
                                break
                            case "Red":
                                var raceState = "RedFlag"
                                break
                            case "Finished":
                                var raceState = "ChequeredFlag"
                                break
                            case "Complete":
                                var ended = true
                                break
                        }
                    }

                    if (messageJSON.Type){
                        session.SessionType = messageJSON.Type
                    }

                    if (session.SessionId != messageJSON.ID){
                        var competitors = []
                        session.blt = null
                        
                        controls = {
                            AutoCol: false,
                            IncludePs: false,
                            ClockShow: true,
                            TowerShow: true,
                            ResultsShow: false,
                            GridShow: false,
                            NotificationShow: false,
                            FastestLapShow: false,
                            ChampionshipShow: true,
                            ChampionshipSelect: 0,
                            ComparisonShow: false,
                            ComparisonLeadDriver: undefined,
                            TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                            ResultsMargin: 0,
                            JumboResultsMargin: 0,
                            GridsMargin: 20,
                            JumboGridsMargin: 0,
                            TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                            CurrentPage: 1,
                            BattleShow: false,
                            BattleDrivers: [],
                            BattlePosition: undefined,
                            ProgressionShow: false,
                            ProgressionPosition: undefined,
                            Col4: "gap",
                            LiveChampsDisplay: 'liveChampHide'
                        }

                    } else {
                        var competitors = session.Competitors
                    }

                    if (session.blt != null){
                        var blt = session.blt
                    } else {
                        var blt = null
                    }

                        session = {
                            SessionStartTime: messageJSON.ActualStart,
                            ResultAvailable: null,
                            ShowNotifications: null,
                            EventId: messageJSON.ID,
                            ServerTime: session.ServerTime || 0,
                            SessionId: messageJSON.ID,
                            SessionName: messageJSON.Name,
                            SessionType: messageJSON.Type,
                            SessionTimeRemaining: timeRemaining || session.TimeRemaining || 0,
                            CircuitMap: messageJSON.TrackName,
                            TrackLength: messageJSON.TrackLength,
                            TrackName: messageJSON.TrackDisplayName,
                            tn: messageJSON.TrackDisplayName,
                            Weather: messageJSON.WeatherConditions,
                            Temperature: null,
                            TrackCondition: messageJSON.TrackConditions,
                            IsTeamEvent: null,
                            MultiCls: messageJSON.Classes,
                            ns: null,
                            nst: null,
                            bn: null,
                            bst: null,
                            bet: null,
                            officials: {
                                CompetitionSecretary: null,
                                Timekeeper: null,
                            },
                            dp: null,
                            blt: blt,
                            bs1: null,
                            bs2: null,
                            bs3: null,
                            tt: null,
                            LengthTime: messageJSON.LengthTime,
                            SD: sd ,
                            flds: [
                                "gd",
                                "ps",
                                "lpe"
                            ],
                            nto: -0.04,
                            ntst: "2022-11-27T15:33:45.329Z",
                            ntip: "132.163.96.2",
                            ntsn: "time.nist.gov",
                            State: raceState || messageJSON.State,
                            fcy: fcy || false,
                            Sequence: null,
                            Sectors: session.Sectors || null,
                            RaceTime: 0,
                            Rd: timeRemaining || messageJSON.TimeRemaining || session.TimeRemaining || 0,
                            Last: false,
                            End: ended || false,
                            show_race_time: false,
                            TimingSystem: null,
                            Time: null,
                            Date: null,
                            Clear: false,
                            Competitors: competitors,
                            TSLGrouped: messageJSON.IsGrouped || session.IsGrouped || false,
                            TSLGroupedType: messageJSON.GroupType || session.GroupType || null,
                            TSLGroupedFL: messageJSON.GroupFastLapTime || session.GroupFastLapTime || null,
                            TSLGroupedFLCID: messageJSON.GroupFastLapCompetitorID || session.GroupFastLapCompetitorID || null,
                    }

                    break
                case "DATA ActiveCompetitor":
                    if (session) {
                        if (messageJSON.PrimaryClass == "GT Academy"){
                            if (messageJSON.SubClass == "GTA"){
                                var nbc = "#2A2D2C"
                                var nfc = "#e9811f"
                            } else if (messageJSON.SubClass == "R"){
                                var nbc = "#ffffff"
                                var nfc = "#e9811f"
                            } else {
                                var nbc = "white"
                                var nfc = "black"
                            }
                            
                        } else if (messageJSON.PrimaryClass == "GT"){
                            if (messageJSON.SubClass == "GT5A"){
                                var nbc = "#e9811f"
                                var nfc = "#ffffff"
                            } else if (messageJSON.SubClass == "GT5P"){
                                var nbc = "#ffffff"
                                var nfc = "#e9811f"
                            } else if (messageJSON.SubClass == "GTP"){
                                var nbc = "#2A2D2C"
                                var nfc = "#e9811f"
                            } else {
                                var nbc = "white"
                                var nfc = "black"
                            }
                            
                        } else if (messageJSON.PrimaryClass == "Junior"){
                            if (messageJSON.SubClass == "J"){
                                var nbc = "#2A2D2C"
                                var nfc = "#e9811f"
                            } else if (messageJSON.SubClass == "R"){
                                var nbc = "#ffffff"
                                var nfc = "#e9811f"
                            } else {
                                var nbc = "white"
                                var nfc = "black"
                            }
                            
                        } else if (session.MultiCls){
                            var classes = session.MultiCls
                            var classIndex = classes.findIndex(x => x.SubClass === messageJSON.SubClass)
                            if (classIndex > -1){
                                var nbc = classes[classIndex].Colour.substring(0,7);
                                var hexRed = parseInt(nbc.substring(1, 3), 16) / 255;
                                var hexGreen = parseInt(nbc.substring(3, 5), 16) / 255;
                                var hexBlue = parseInt(nbc.substring(5, 7), 16) / 255;
                                var lum = 0.2126 * hexRed + 0.7152 * hexGreen + 0.0722 * hexBlue;
                                if (lum > 0.5) {
                                    var nfc = "black"
                                } else {
                                    var nfc = "white"
                                }
                            } else {
                                var nbc = "#2faae1"
                                var nfc = "#ffffff"
                            }
                        } else {
                            var nbc = "#2faae1"
                            var nfc = "#ffffff"
                        }

                        if (session.Competitors){
                            var competitorsState = session.Competitors
                            var driverIndex = competitorsState.findIndex( x => x.CompetitorId == messageJSON.ID)
                            console.log(driverIndex)
                        }

                    }

                    if (messageJSON.FirstName){
                        var compName = messageJSON.FirstName + " " + messageJSON.LastName || ""
                        var picName = messageJSON.FirstName +  messageJSON.LastName || ""
                    } else {
                        var compName = messageJSON.DisplayName
                        var picName =  messageJSON.DisplayName || ""
                    }
                    
                    if(driverIndex == -1){
                        var driver = {
                            "CompetitorId": messageJSON.ID || "",
                            "CompetitorNumber": messageJSON.StartNumber || "",
                            "CompetitorName": compName || "",
                            "Picture" : "https://alphalive.co.uk/streamphotos/"+series+"/"+picName.toUpperCase() || "",
                            "CompetitorClass": messageJSON.PrimaryClass || "",
                            "CompetitorSubClass": messageJSON.SubClass || "",
                            "TeamName": messageJSON.Sponsor || "",
                            "vehicle": messageJSON.Vehicle || "",
                            "DriverName": messageJSON.ShortName || "",
                            "Position": messageJSON.GridPos || 999,
                            "NumberOfLaps": 0,
                            "LastLaptime": "",
                            "LapData": [],
                            "RaceTime": null,
                            "RunningTime": null,
                            "BestLaptime": null,
                            "BestLapNumber": null,
                            "IsBestLapInRace": false,
                            "Seen": false,
                            "InPit": false,
                            "Retired": false,
                            "GridPosition": messageJSON.GridPos || 999,
                            "PositionChange": 0,
                            "PosChangeClass" : "positionneutral",
                            "posChangeTime" : 0,
                            "Gap": 0,
                            "gd": 999,
                            "Behind": 0,
                            "Split1": 0,
                            "Split2": 0,
                            "Split3": 0,
                            "IsBestSector1": false,
                            "pbs1": 0,
                            "IsBestSector2": false,
                            "pbs2": 0,
                            "IsBestSector3": false,
                            "pbs3": 0,
                            "cs": null,
                            "el": null,
                            "TakenChequered": false,
                            "ocl": null,
                            "clnr": null,
                            "blnr": null,
                            "tt": null,
                            "nat": messageJSON.Nationality,
                            "ps": 0,
                            "lpe": 0,
                            "nbc": nbc,
                            "nfc": nfc,
                            "es1": null,
                            "es2": null
                        }
                        session.Competitors.push(driver) 
                    } else {
                        var driver = {
                            "CompetitorId": messageJSON.ID || "",
                            "CompetitorNumber": messageJSON.StartNumber || "",
                            "CompetitorName": compName || "",
                            "Picture" : "https://alphalive.co.uk/streamphotos/"+series+"/"+picName.toUpperCase() || "",
                            "CompetitorClass": messageJSON.PrimaryClass || "",
                            "CompetitorSubClass": messageJSON.SubClass || "",
                            "CurrentLap": session.Competitors[driverIndex].CurrentLap || {"LapNo": 0, "Sectors": []},
                            "TeamName": messageJSON.Sponsor || "",
                            "vehicle": messageJSON.Vehicle || "",
                            "DriverName": messageJSON.ShortName || "",
                            "Position": session.Competitors[driverIndex].Position || messageJSON.GridPos || 999,
                            "NumberOfLaps": session.Competitors[driverIndex].NumberOfLaps || 0,
                            "LastLaptime": session.Competitors[driverIndex].LastLaptime || "",
                            "LapData": session.Competitors[driverIndex].LapData || [],
                            "RaceTime": session.Competitors[driverIndex].RaceTime || null,
                            "RunningTime": session.Competitors[driverIndex].RunningTime || null,
                            "BestLaptime": session.Competitors[driverIndex].BestLaptime || null,
                            "BestLapNumber": session.Competitors[driverIndex].BestLapNumber || null,
                            "IsBestLapInRace": session.Competitors[driverIndex].IsBestLapInRace || false,
                            "Seen": session.Competitors[driverIndex].Seen || false,
                            "InPit": session.Competitors[driverIndex].InPit || false,
                            "Retired": session.Competitors[driverIndex].Retired || false,
                            "GridPosition": session.Competitors[driverIndex].GridPosition || messageJSON.GridPos || 999,
                            "PositionChange": 0,
                            "PosChangeClass" : "positionneutral",
                            "posChangeTime" : 0,
                            "Gap": session.Competitors[driverIndex].Gap,
                            "gd": session.Competitors[driverIndex].gd || 999,
                            "Behind": session.Competitors[driverIndex].Behind,
                            "Split1": session.Competitors[driverIndex].Split1 || 0,
                            "Split2": session.Competitors[driverIndex].Split2 || 0,
                            "Split3": session.Competitors[driverIndex].Split3 || 0,
                            "IsBestSector1": session.Competitors[driverIndex].IsBestSector1 || false,
                            "pbs1": session.Competitors[driverIndex].pbs1 || 0,
                            "IsBestSector2": session.Competitors[driverIndex].IsBestSector2 || false,
                            "pbs2": session.Competitors[driverIndex].pbs2 || 0,
                            "IsBestSector3": session.Competitors[driverIndex].IsBestSector3 || false,
                            "pbs3": session.Competitors[driverIndex].pbs3 || 0,
                            "cs": session.Competitors[driverIndex].cs || null,
                            "el": session.Competitors[driverIndex].el || null,
                            "TakenChequered": session.Competitors[driverIndex].TakenChequered || false,
                            "ocl": session.Competitors[driverIndex].ocl || null,
                            "clnr": session.Competitors[driverIndex].clnr || null,
                            "blnr": session.Competitors[driverIndex].blnr || null,
                            "tt": session.Competitors[driverIndex].tt || null,
                            "nat": messageJSON.Nationality,
                            "ps": session.Competitors[driverIndex].ps || 0,
                            "lpe": session.Competitors[driverIndex].lpe || 0,
                            "nbc": nbc,
                            "nfc": nfc,
                            "es1": session.Competitors[driverIndex].es1 || null,
                            "es2": session.Competitors[driverIndex].es2 || null,
                            "GroupBehind": session.Competitors[driverIndex].GroupBehind || null,
                            "GroupBestLaptime": session.Competitors[driverIndex].GroupBestLaptime || null,
                            "GroupGap": session.Competitors[driverIndex].GroupGap || null,
                            "GroupNumberOfLaps": session.Competitors[driverIndex].GroupNumberOfLaps || null,
                            "GroupPosition": session.Competitors[driverIndex].GroupPosition || null,
                            "GroupPositionChange": session.Competitors[driverIndex].GroupPositionChange || null,
                        }
                        session.Competitors[driverIndex] = driver
                    }
                    
                    break
                case "DATA Result":
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                    if (driverIndex > -1){
                        if (competitorsState[driverIndex].TakenChequered == true || messageJSON.InPit == 1 || messageJSON.Status == "Missing"){
                            if (messageJSON.Diff < 0) {
                                var gap = messageJSON.Diff + " Laps"
                            } else {
                                var gap = this.msToTime(messageJSON.Diff)
                            }

                            if (messageJSON.Gap < 0){
                                var behind = messageJSON.Gap + " Laps"
                                if (competitorsState[driverIndex].SectorPosUpdate != true ){
                                    competitorsState[driverIndex].Position = messageJSON.Position
                                }
                            } else {
                                var behind = this.msToTime(messageJSON.Gap)
                                competitorsState[driverIndex].Position = messageJSON.Position
                            }

                            if (messageJSON.Status == "Missing"){
                                var retired = true
                            } else {
                                var retired = false
                            }

                            if (messageJSON.Status == "Finished"){
                                var finished = true
                            } else {
                                var finished = false
                            }

                            competitorsState[driverIndex].Gap = gap
                            competitorsState[driverIndex].Behind = behind || competitorsState[driverIndex].Behind 
                            competitorsState[driverIndex].NumberOfLaps = messageJSON.Laps
                            competitorsState[driverIndex].ps = messageJSON.PitStops
                            competitorsState[driverIndex].InPit = messageJSON.InPit
                            competitorsState[driverIndex].PositionChange = messageJSON.PositionChange
                            competitorsState[driverIndex].BestLaptime = messageJSON.FastLapTime/1000
                            competitorsState[driverIndex].TakenChequered = finished
                            competitorsState[driverIndex].Retired = retired
                        }
                    }
                    break
                case "DATA GroupResult":
                    console.log("GroupResult", messageJSON)
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                    console.log(driverIndex)
                    if (driverIndex > -1){
                        if (messageJSON.Diff < 0) {
                            var gap = messageJSON.Diff + " Laps"
                        } else {
                            var gap = this.msToTime(messageJSON.Diff)
                        }

                        if (messageJSON.Gap < 0){
                            var behind = messageJSON.Gap + " Laps"
                        } else {
                            var behind = this.msToTime(messageJSON.Gap) 
                        }
                        
                        if (messageJSON.Status == "Missing"){
                            var retired = true
                        } else {
                            var retired = false
                        }

                        if (messageJSON.Status == "Finished"){
                            var finished = true
                        } else {
                            var finished = false
                        }
                        competitorsState[driverIndex].GroupPosition = messageJSON.Position
                        competitorsState[driverIndex].GroupGap = gap
                        competitorsState[driverIndex].GroupBehind = behind
                        competitorsState[driverIndex].GroupNumberOfLaps = messageJSON.Laps
                        // competitorsState[driverIndex].Groupps = messageJSON.PitStops
                        // competitorsState[driverIndex].GroupInPit = messageJSON.InPit
                        competitorsState[driverIndex].GroupPositionChange = messageJSON.PositionChange
                        competitorsState[driverIndex].GroupBestLaptime = messageJSON.FastLapTime/1000
                        // competitorsState[driverIndex].GroupTakenChequered = finished
                        // competitorsState[driverIndex].GroupRetired = retired
                    }
                    break
                case "DATA Lap":
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                    if (driverIndex > -1){
                        var lapObj = {
                        "LapNo": messageJSON.Lap,
                        "LapTime": messageJSON.LapTime/1000,
                        "Sectors": messageJSON.SectorTimes,
                        "PassingTime": messageJSON.PassingTime
                        }
                        competitorsState[driverIndex].LapData.push(lapObj)

                        if (parseInt(messageJSON.Lap) > 2){
                            if(session.blt != null){
                                var currentBestTime = session.blt.tm
                                
                                if (lapObj.LapTime < currentBestTime){
                                    var blt = {
                                        cn: competitorsState[driverIndex].CompetitorNumber,
                                        cnpb: competitorsState[driverIndex].nbc,
                                        cnpf: competitorsState[driverIndex].nfc,
                                        ln: lapObj.LapNo,
                                        tm: lapObj.LapTime,
                                        sectors: lapObj.Sectors,
                                        shown: false,
                                        pusher: true
                                    }
                                    competitorsState[driverIndex].IsBestLapInRace = true
                                    session.blt = blt
                                    var i = 0

                                    while(i< competitorsState.length){
                                        if (i != driverIndex){
                                            competitorsState[i].IsBestLapInRace = false
                                        }
                                        i++
                                    }
                                }
                            } else {
                                var blt = {
                                    cn: competitorsState[driverIndex].CompetitorNumber,
                                    cnpb: competitorsState[driverIndex].nbc,
                                    cnpf: competitorsState[driverIndex].nfc,
                                    ln: lapObj.LapNo,
                                    tm: lapObj.LapTime,
                                    sectors: lapObj.Sectors,
                                    shown: false,
                                    pusher: true
                                }
                                competitorsState[driverIndex].IsBestLapInRace = true
                                session.blt = blt
                                var i = 0
                                while(i< competitorsState.length){
                                    if (i != driverIndex){
                                        competitorsState[i].IsBestLapInRace = false
                                    }
                                    i++
                                }
                            }
                        }

                        if (messageJSON.Diff < 0) {
                            if (messageJSON.Diff == -1){
                                var gap = messageJSON.Diff + " Lap"
                            } else {
                                var gap = messageJSON.Diff + " Laps"
                            }
                        } else {
                            var gap = this.msToTime(messageJSON.Diff)
                        }

                        if (messageJSON.Gap < 0){
                            if (messageJSON.Gap == -1){
                                var behind = messageJSON.Gap + " Lap"
                            } else {
                                var behind = messageJSON.Gap + " Laps"
                            }
                            
                            if (competitorsState[driverIndex].SectorPosUpdate != true ){
                                competitorsState[driverIndex].Position = messageJSON.Position
                            }
                        } else {
                            var behind = this.msToTime(messageJSON.Gap)
                            competitorsState[driverIndex].Position = messageJSON.Position
                        }

                    }
                    competitorsState[driverIndex].LastLaptime = messageJSON.LapTime/1000
                    competitorsState[driverIndex].SectorPosUpdate = false
                    competitorsState[driverIndex].Gap = gap || competitorsState[driverIndex].gap
                    competitorsState[driverIndex].Behind = behind || competitorsState[driverIndex].Behind 
                    
                    break
                case "DATA Grid":

                    break
                case "DATA Ping":
                    if (messageJSON.SessionTimeRemaining){
                        session.SessionTimeRemaining = messageJSON.SessionTimeRemaining/1000
                        var currentTime = Date.now()
                        session.ServerTime = Math.round(currentTime);
                    }
                    break
                case "DATA RCMsg":
                    var messageArray = this.state.Notifications
                    if (messageJSON.Message != ""){
                        var rcMSG = {
                        t: "",
                        nt: messageJSON.Message,
                        cnu: "",
                        cna: "",
                        displayed: false
                        }
                        messageArray.push(rcMSG)
                        this.setState({
                            Notifications: messageArray
                        })
                    }
                    
                    break
                case "DATA Sector":
                    console.log("Sector")
                    var sector = JSON.parse(data.message)
                    console.log(sector)
                    if (sector.IsSpeedTrap == 0){
                        if(session.Sectors){
                            var sectorIndex = session.Sectors.findIndex( x => x.ID == parseInt(sector.ID))
                            if (sectorIndex > -1){
                                if (sector.BestTimeCompetitorID){
                                    session.Sectors[sectorIndex].BestTime = sector.BestTime
                                }
                                if (sector.BestTimeCompetitorID){
                                    session.Sectors[sectorIndex].BestTimeCompetitorID = sector.BestTimeCompetitorID
                                }
                            } else {
                                session.Sectors.push(sector)
                            }
                        } else {
                            session.Sectors = [sector]
                        }
                    }
                    break
                case "DATA Inter":
                    console.log("Inter")
                    if (session.Sectors){
                        var inter = JSON.parse(data.message)
                        console.log(inter)
                        var competitorsState = session.Competitors
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId == parseInt(inter.CompetitorID))

                        var sectors = session.Sectors
                        var sectorIndex = sectors.findIndex( x => x.ID == parseInt(inter.SectorID))

                        if (driverIndex > -1){
                            var driver = competitorsState[driverIndex]

                            if (sectorIndex > -1){
                                if (driver.LapData){
                                    if (driver.CurrentLap){
                                        if (driver.CurrentLap.LapNo < inter.Lap){
                                            driver.CurrentLap = {
                                                "LapNo": inter.Lap,
                                                "Sectors": [inter]
                                            }
                                        } else {
                                            driver.CurrentLap.Sectors.push(inter)
                                        }
                                    } else {
                                        driver.CurrentLap = {
                                            "LapNo": inter.Lap,
                                            "Sectors": [inter]
                                        }
                                    }
                                }
                            }
                        }

                        var lapObj = {
                            "LapNo": messageJSON.Lap,
                            "LapTime": messageJSON.LapTime/1000,
                            "Sectors": messageJSON.SectorTimes
                            }
                    }
                    
                    break
                case "Championship":
                    var Championship = messageJSON.ChampionshipPositions
                    console.log(Championship)
                    this.setState({
                        championship: Championship,
                    })
                    break
                case "DATA Sort1":
                    console.log("Sort1", messageJSON)
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                    if (driverIndex > -1){
                        if (competitorsState[driverIndex].TakenChequered != true){
                            if (messageJSON.Diff < 0) {
                                var gap = messageJSON.Diff + " Laps"
                            } else {
                                if (competitorsState[driverIndex].Gap){
                                    var lapsDown = competitorsState[driverIndex].Gap.indexOf("Lap")
                                } else {
                                    var lapsDown = -1
                                }
                                
                                if(lapsDown == -1){
                                    var gap = this.msToTime(messageJSON.Diff)
                                } else {
                                    var gap = competitorsState[driverIndex].Gap
                                }  
                            }

                            if (messageJSON.Gap < 0){
                                var behind = messageJSON.Gap + " Laps"
                                if (competitorsState[driverIndex].SectorPosUpdate != true ){
                                    competitorsState[driverIndex].Position = messageJSON.Position
                                }
                            } else {
                                if(competitorsState[driverIndex].Behind){
                                    var behindLapsDown = competitorsState[driverIndex].Behind.indexOf("Lap")
                                } else {
                                    var behindLapsDown = -1
                                }
                                
                                if(behindLapsDown == -1){
                                    var behind = this.msToTime(messageJSON.Gap)
                                } else {
                                    var behind = competitorsState[driverIndex].Behind
                                }
                                
                                competitorsState[driverIndex].Position = messageJSON.Position
                            }

                            if (messageJSON.Status == "Missing"){
                                var retired = true
                            } else {
                                var retired = false
                            }

                            if (messageJSON.Status == "Finished"){
                                var finished = true
                            } else {
                                var finished = false
                            }

                            competitorsState[driverIndex].Gap = gap
                            competitorsState[driverIndex].Behind = behind || competitorsState[driverIndex].Behind 
                            competitorsState[driverIndex].NumberOfLaps = messageJSON.Laps
                            competitorsState[driverIndex].ps = messageJSON.PitStops
                            competitorsState[driverIndex].InPit = messageJSON.InPit
                            competitorsState[driverIndex].PositionChange = messageJSON.PositionChange
                            competitorsState[driverIndex].BestLaptime = messageJSON.FastLapTime/1000
                            competitorsState[driverIndex].TakenChequered = finished
                            competitorsState[driverIndex].Retired = retired
                        }
                    }
                    break
                }
            
            } else if (this.state.ClientStates.TimingSystem == "ApexTest"){

            }
            
        } else if (data.author == "Controller"){
            console.log(data)
            if(data.type == "Database"){
                this.setState({
                    ClientStates: data.message
                })
            } else if (data.type == "State") {
                console.log("State", data.message)
                if (data.message == "Yellow"){
                    session.fcy = true
                    session.End = false
                } else if (data.message == "Running"){
                    if (!session.StartTime){
                        session.StartTime = Date.now()
                    } else {
                        console.log("HERE")
                    }
                    session.fcy = false
                    session.Last = false
                    session.State = data.message
                } else if (data.message == "RedFlag"){
                    session.fcy = false
                    session.State = data.message
                    session.End = false
                } else if (data.message == "ChequeredFlag"){
                    session.State = data.message
                    session.fcy = false
                    session.Last = false
                    session.End = true
                } else if (data.message == "LastLap"){
                } else if (data.message == "Starting"){
                        session.State = "Starting"
                        console.log("here")
                        var lapsIndex = session.SD.indexOf("Laps")

                        if (lapsIndex > -1){
                            this.setState({timeRemaining: "0 / "+session.SD})
                        } else {
                        }
                        session.Last = false
                        session.End = false
                } else {
                    console.log("HERE1")
                    session.fcy = false
                    session.State = data.message
                    session.Last = false
                    session.End = false
                }
                

            } else if (data.type == "ChampionshipSelect"){
                console.log("ChampChange")
                controls.ChampionshipSelect = parseInt(data.message)
            } else {
                switch(data.message){
                case "resultsShow": 
                    controls.ResultsMargin = 0
                    controls.JumboResultsMargin = 0
                    controls.ResultsShow = true
					controls.ClockShow = false
                    break
                case "RaceDuration":
                    this.setState({
                        RaceDuration: data.message2
                    })
                    break
                case "TickerName":
                    this.setState({
                        TickerName: data.message2
                    })
                    break
                case "SessionName":
                    this.setState({
                        selectedRaceName: data.message2
                    })
                    break
                case "SessionType":
                    this.setState({
                        SessionType: data.message2
                    })
                    break
                case "gridShow":
                    controls.GridsMargin = 20
                    controls.JumboGridsMargin = 0
                    controls.GridShow = true
                    controls.ClockShow = false
                    break
                case "resultsPage":
                    var resultRowHeight = parseInt(this.state.ClientStates.ResultRowHeight) + parseInt(this.state.ClientStates.ResultRowSpacing)
                    var resultTransition = this.state.ClientStates.ResultsFixedPos * resultRowHeight
                    controls.ResultsMargin = controls.ResultsMargin - resultTransition
                    var jumboResultRowHeight = parseInt(this.state.ClientStates.JumboResultRowHeight) + parseInt(this.state.ClientStates.JumboResultRowSpacing)
                    var jumboResultTransition = this.state.ClientStates.JumboResultsFixedPos * jumboResultRowHeight
                    controls.JumboResultsMargin = controls.JumboResultsMargin - jumboResultTransition
                    break
                case "gridPage":
                    //This needs to be configured
                    var gridRowHeight = parseInt(this.state.ClientStates.GridRowHeight) + parseInt(this.state.ClientStates.GridRowSpacing)
                    var gridTransition = this.state.ClientStates.GridVisibleRows * gridRowHeight
                    controls.GridsMargin = controls.GridsMargin - gridTransition
                    var jumboGridRowHeight = parseInt(this.state.ClientStates.JumboGridRowHeight) + parseInt(this.state.ClientStates.JumboGridRowSpacing)
                    var jumboGridTransition = this.state.ClientStates.JumboGridVisibleRows * jumboGridRowHeight
                    controls.JumboGridsMargin = controls.JumboGridsMargin - jumboGridTransition
                    break
                case "resultsHide":
                    controls.ResultsShow = false
                    break
                case "gridHide":
                    controls.GridShow = false
                    break
                case "towerShow":
                    controls.TowerShow = true
                    break
                case "towerHide":
                    controls.TowerShow = false
                    break
                case "clockShow":
                    controls.ClockShow = true
                    break
                case "clockHide":
                    controls.ClockShow = false
                    break
                case "battleShow":
                    console.log(data.message2)
                    controls.BattlePosition = parseInt(data.message2)
                    controls.BattleDrivers = data.message3
                    controls.BattleShow = true
                    if(controls.BattlePosition > 12){
                        this.pages()
                    }
                    break
                case "battleClear":
                    controls.BattleShow = false
                    break
                case "compareShow":
                    controls.ComparisonShow = true
                    break
                case "champStandingsShow":
                    controls.LiveChampsDisplay = "liveChampShow"
                    break
                case "champStandingsHide":
                    controls.LiveChampsDisplay = "liveChampHide"
                    break
                case "compareHide":
                    controls.ComparisonShow = false
                    break
                case "compare":
                    controls.ComparisonLeadDriver = data.message2
                    break
                case "col4Pos":
                    controls.Col4 = "positions"
                    break
                case "col4Gap":
                    controls.Col4 = "gap"
                    break
                case "col4Diff":
                    controls.Col4 = "diff"
                    break
                case "col4Pits":
                    controls.Col4 = "pits"
                    break
                case "col4None":
                    controls.Col4 = "none"
                    break
                case "progressionShow":
                    controls.ProgressionShow = true
                    controls.ProgressionPosition = data.message2
                    break
                case "progressionClear":
                    controls.ProgressionShow = false
                    controls.ProgressionPosition = data.message2
                    break
                case "expand":
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId == parseInt(data.message2))

                    if (data.message2 != controls.ExpandDriverID){
                        controls.Expand = true
                        controls.ExpandDriverID = data.message2
                        controls.ExpandIndex = driverIndex
                        if (competitorsState[driverIndex].Position > 10){
                            this.pages()
                        }
                    } else {
                        controls.Expand = false
                        controls.ExpandDriverID = undefined
                        controls.ExpandIndex = undefined
                    }
                    break
                case "getMeetings":
                    console.log("https://results.alphatiming.co.uk/api/v1/wmkc/event/")
                    console.log(this.state.Session.SessionUuid)
                    this.getMeetings()
                    break
                case "getRaceList":
                    this.getRaceList(data.message2, true)
                    break  
                case "getGrid":
                    this.getGrid(data.message2, true)
                    break  
                case "changeHeader":
                    this.changeHead(data.message2, true)
                    break
                }            
            }
        } else if (data.author == "Timing"){
            console.log(JSON.parse(data.message))
            var newStandings = JSON.parse(data.message)
            
            if (this.state.Session.Competitors){
                if (data.type == "Standings"){
                    var i = 0
                    if(this.state.Session.SessionName != data.session){
                        controls = {
                            AutoCol: false,
                            IncludePs: false,
                            ClockShow: true,
                            TowerShow: true,
                            ResultsShow: false,
                            GridShow: false,
                            NotificationShow: false,
                            FastestLapShow: false,
                            ChampionshipShow: true,
                            ChampionshipSelect: 0,
                            ComparisonShow: false,
                            ComparisonLeadDriver: undefined,
                            TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                            ResultsMargin: 0,
                            JumboResultsMargin: 0,
                            GridsMargin: 10,
                            JumboGridsMargin: 0,
                            TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                            CurrentPage: 1,
                            BattleShow: false,
                            BattleDrivers: [],
                            BattlePosition: undefined,
                            ProgressionShow: false,
                            ProgressionPosition: undefined,
                            Col4: "gap",
                            Expand: false,
                            ExpandDriverID: undefined,
                          }
                        session.SessionName = data.session
                        session.Competitors = data.newStandings
                        if (data.laps){
                            console.log(data.laps)
                            session.Rd = data.laps+"L"
                            console.log(session.Rd)
                        } else {
                            session.SD = data.duration
                        }
                    } else {
                       var competitorsState = session.Competitors

                        while(i < newStandings.length){
                            var driverIndex = competitorsState.findIndex( x => parseInt(x.CompetitorId) == parseInt(newStandings[i].CompetitorId))
                            //check for fastest lap
                            console.log(session.blt.tm, newStandings[i].CompetitorName,competitorsState[driverIndex].BestLaptime, newStandings[i].BestLaptime)
                            if (session.blt.tm > newStandings[i].BestLaptime){
                                var fastestLap = session.blt 
                                console.log(true)
                                if (fastestLap.tm > newStandings[i].BestLaptime){
                                    
                                    var newFL = {
                                        cn: newStandings[i].CompetitorNumber,
                                        cnpb: newStandings[i].nbc,
                                        cnpf: newStandings[i].nfc,
                                        ln: newStandings[i].NumberOfLaps,
                                        tm: newStandings[i].BestLaptime,
                                        shown: false,
                                        pusher: true
                                    }

                                    newStandings[i].IsBestLapInRace = true

                                    session.blt = newFL
                                }
                            } else if (competitorsState[driverIndex].IsBestLapInRace == true){
                                //check if driver is still the fastest
                                if (session.blt.cn == newStandings[i].CompetitorNumber){
                                    newStandings[i].IsBestLapInRace = true
                                } else {
                                    newStandings[i].IsBestLapInRace = false
                                }
                            }

                            if (competitorsState[driverIndex].NumberOfLaps != newStandings[i].NumberOfLaps){
                                var newLapObj = {
                                    "LapNo": newStandings[i].NumberOfLaps,
                                    "LapTime": newStandings[i].LastLaptime
                                }
                                if(competitorsState[driverIndex].LapData){
                                    newStandings[i].LapData.push(newLapObj)
                                } else {
                                    newStandings[i].LapData = [newLapObj]
                                }
                                
                            }

                            if(driverIndex > -1){
                                session.Competitors[driverIndex] = newStandings[i] 
                            } else {
                                session.Competitors.push(newStandings[i])
                            }
                            i++
                        } 
                    }

                    if (data.raceState){
                        if (data.raceState == "finish-flag"){
                            session.State = "ChequeredFlag"
                            session.End = true
                        } else if (data.raceState == "finish-flag"){
                            session.State = "RedFlag"
                            session.fcy = false
                            session.End = false
                        } else if(data.raceState == "green-flag"){
                            session.State = "Running"
                            session.fcy = false
                            session.End = false
                        } else if(data.raceState == "yellow-flag"){
                            session.State = "Running"
                            session.fcy = true
                            session.End = false
                        }
                    }

                    if (data.laps){
                        console.log(data.laps)
                        session.Rd = data.laps+"L"
                        console.log(session.Rd)
                    } else {
                        session.SD = data.duration
                    }
                }
            } else {
                console.log("here")
                var newSession = {
                    SessionStartTime: "",
                    ResultAvailable: true,
                    ShowNotifications: true,
                    EventId: 1,
                    SessionId: 1,
                    SessionName: data.session || "",
                    SessionType: "Race",
                    CircuitMap: "croft_circuit",
                    TrackLength: 1128,
                    TrackName: "Croft Circuit",
                    Weather: null,
                    Temperature: null,
                    TrackCondition: null,
                    IsTeamEvent: false,
                    MultiCls: false,
                    ns: null,
                    nst: null,
                    bn: null,
                    bst: null,
                    bet: null,
                    officials: {
                        CompetitionSecretary: "",
                        Timekeeper: ""
                    },
                    dp: 2,
                    blt: {
                        cn: 11,
                        cnpb: "#FF0000",
                        cnpf: "#FFFFFF",
                        ln: 17,
                        tm: 4620000000000000,
                        shown: true
                    },
                    bs1: {
                        cn: 11,
                        cnpb: "#FF0000",
                        cnpf: "#FFFFFF",
                        ln: 17,
                        tm: 15060
                    },
                    bs2: {
                        cn: 33,
                        cnpb: "#FF0000",
                        cnpf: "#FFFFFF",
                        ln: 17,
                        tm: 31030
                    },
                    bs3: null,
                    tt: null,
                    SD: data.duration || "16 Laps" || "",
                    flds: [
                        "gd",
                        "ps",
                        "lpe"
                    ],
                    nto: -0.04,
                    ntst: "2022-11-27T15:33:45.329Z",
                    ntip: "132.163.96.2",
                    ntsn: "time.nist.gov",
                    State: "Formation",
                    fcy: false,
                    Sequence: 1273,
                    Sectors: 3,
                    RaceTime: 0,
                    Rd: "15M",
                    Last: false,
                    End: false,
                    show_race_time: true,
                    TimingSystem: "wmkc",
                    Date: "2022-11-27",
                    Clear: false,
                    Competitors: newStandings
                }
                session = newSession
            }

            
        } else if (data.author == "rMonitor"){
            if (alpha != true){
                            let rMonitorTimeStamp = (timestamp) => {
                const [hours, minutes, secondsWithMs] = timestamp.split(':');
                const [seconds, milliseconds] = secondsWithMs.split('.');
            
                return (
                    parseInt(hours, 10) * 60 * 60 * 1000 +
                    parseInt(minutes, 10) * 60 * 1000 +
                    parseInt(seconds, 10) * 1000 +
                    (milliseconds ? parseInt(milliseconds, 10) : 0) // Handle possible absence of milliseconds
                );
              }

            let msToTime = (timestamp) => {
                function pad(n, z = 2) {
                    return ('00' + n).slice(-z);
                }
            
                if (timestamp === 9223372036854776000) {
                    return "";
                }
            
                let ms = timestamp % 1000;
                let seconds = Math.floor(timestamp / 1000) % 60;
                let minutes = Math.floor(timestamp / (1000 * 60)) % 60;
                let hours = Math.floor(timestamp / (1000 * 60 * 60));
            
                if (hours === 0 && minutes === 0 && seconds === 0) {
                    return "0." + pad(ms, 3);
                } else if (hours === 0 && minutes === 0 && seconds < 10) {
                    return seconds + '.' + pad(ms, 3);
                } else if (hours === 0 && minutes === 0) {
                    return pad(seconds) + '.' + pad(ms, 3);
                } else if (hours === 0 && minutes < 10) {
                    return minutes + ':' + pad(seconds) + '.' + pad(ms, 3);
                } else if (hours === 0) {
                    return pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3);
                } else {
                    return hours + ':' + pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3);
                }
            }

            switch(data.message.Identifier){
                case "$A":
                    if(session.Competitors){
                        console.log(session.Competitors)
                        console.log(session.MultiCls)
                        var index = session.Competitors.findIndex(x => x.CompetitorId == data.message.RegNum);
                        if (index > -1){

                            if(data.message.FirstName){
                                var name = data.message.FirstName + " " + data.message.LastName
                            } else {
                                var name = data.message.LastName
                            }

                            session.Competitors[index].CompetitorClass = data.message.ClassNumber
                            session.Competitors[index].CompetitorName = name
                            session.Competitors[index].nat = data.message.Nationality || session.Competitors[index].nat
                            session.Competitors[index].CompetitorNumber = data.message.Number
                            session.Competitors[index].TeamName = data.message.AdditionalData || session.Competitors[index].TeamName
                        } else {
                            //get category info
                            if(session){
                                if(session.MultiCls){
                                    var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);
                                
                                    if(data.message.FirstName){
                                        var name = data.message.FirstName + " " + data.message.LastName
                                    } else {
                                        var name = data.message.LastName
                                    }

                                    var objComp = {
                                        "CompetitorId": data.message.RegNum,
                                        "CompetitorNumber": data.message.Number,
                                        "CompetitorName": name,
                                        "CompetitorClass": data.message.ClassNumber,
                                        "CompetitorSubClass": "",
                                        "DriverName": null,
                                        "Position": 999,
                                        "NumberOfLaps": 0,
                                        "LastLaptime": null,
                                        "RaceTime": null,
                                        "RunningTime": null,
                                        "BestLaptime": null,
                                        "BestLapNumber": null,
                                        "IsBestLapInRace": false,
                                        "IsCurrentLapPBestLap": false,
                                        "Seen": true,
                                        "InPit": false,
                                        "Retired": false,
                                        "GridPosition": 999,
                                        "PositionChange": 0,
                                        "Gap": null,
                                        "gd": null,
                                        "Behind": null,
                                        "Split1": null,
                                        "Split2": null,
                                        "Split3": null,
                                        "IsBestSector1": false,
                                        "pbs1": false,
                                        "IsBestSector2": false,
                                        "pbs2": false,
                                        "IsBestSector3": false,
                                        "pbs3": false,
                                        "cs": null,
                                        "el": null,
                                        "TakenChequered": false,
                                        "ocl": null,
                                        "clnr": false,
                                        "blnr": false,
                                        "tt": null,
                                        "nat": data.message.Nationality,
                                        "ps": 0,
                                        "lpe": 0,
                                        "nbc": this.state.ClientStates.Nbc,
                                        "nfc": this.state.ClientStates.Nfc,
                                        "es1": 18810,
                                        "es2": 33700
                                      }
                                
                                } else {

                                    if(data.message.FirstName){
                                        var name = data.message.FirstName + " " + data.message.LastName
                                    } else {
                                        var name = data.message.LastName
                                    }
                                    var objComp = {
                                        "CompetitorId": data.message.RegNum,
                                        "CompetitorNumber": data.message.Number,
                                        "CompetitorName": name,
                                        "CompetitorClass": data.message.ClassNumber,
                                        "CompetitorSubClass": "",
                                        "DriverName": null,
                                        "Position": 999,
                                        "NumberOfLaps": 0,
                                        "LastLaptime": null,
                                        "RaceTime": null,
                                        "RunningTime": null,
                                        "BestLaptime": null,
                                        "BestLapNumber": null,
                                        "IsBestLapInRace": false,
                                        "IsCurrentLapPBestLap": false,
                                        "Seen": true,
                                        "InPit": false,
                                        "Retired": false,
                                        "GridPosition": 999,
                                        "PositionChange": 0,
                                        "Gap": null,
                                        "gd": null,
                                        "Behind": null,
                                        "Split1": null,
                                        "Split2": null,
                                        "Split3": null,
                                        "IsBestSector1": false,
                                        "pbs1": false,
                                        "IsBestSector2": false,
                                        "pbs2": false,
                                        "IsBestSector3": false,
                                        "pbs3": false,
                                        "cs": null,
                                        "el": null,
                                        "TakenChequered": false,
                                        "ocl": null,
                                        "clnr": false,
                                        "blnr": false,
                                        "tt": null,
                                        "nat": null,
                                        "ps": 0,
                                        "lpe": 0,
                                        "nbc": this.state.ClientStates.Nbc,
                                        "nfc": this.state.ClientStates.Nfc,
                                        "es1": 18810,
                                        "es2": 33700
                                      }
                                }
                            }                        

                            session.Competitors.push(objComp)
                        }
                    } else {
                        //get category info
                        var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);

                        if(data.message.FirstName){
                            var name = data.message.FirstName + " " + data.message.LastName
                        } else {
                            var name = data.message.LastName
                        }

                        var objComp = {
                            "CompetitorId": data.message.RegNum,
                            "CompetitorNumber": data.message.Number,
                            "CompetitorName": name,
                            "CompetitorClass": data.message.ClassNumber,
                            "CompetitorSubClass": "",
                            "DriverName": null,
                            "Position": 999,
                            "NumberOfLaps": 0,
                            "LastLaptime": null,
                            "RaceTime": null,
                            "RunningTime": null,
                            "BestLaptime": null,
                            "BestLapNumber": null,
                            "IsBestLapInRace": false,
                            "IsCurrentLapPBestLap": false,
                            "Seen": true,
                            "InPit": false,
                            "Retired": false,
                            "GridPosition": 999,
                            "PositionChange": 0,
                            "Gap": null,
                            "gd": null,
                            "Behind": null,
                            "Split1": null,
                            "Split2": null,
                            "Split3": null,
                            "IsBestSector1": false,
                            "pbs1": false,
                            "IsBestSector2": false,
                            "pbs2": false,
                            "IsBestSector3": false,
                            "pbs3": false,
                            "cs": null,
                            "el": null,
                            "TakenChequered": false,
                            "ocl": null,
                            "clnr": false,
                            "blnr": false,
                            "tt": null,
                            "nat": null,
                            "ps": 0,
                            "lpe": 0,
                            "nbc": this.state.ClientStates.Nbc,
                            "nfc": this.state.ClientStates.Nfc,
                            "es1": 18810,
                            "es2": 33700
                          }

                        session.Competitors = [objComp]

                    }
                    break
                case "$B":
                    if (session.SessionId != data.message.RegNum){
                        //Initialises a new session
                        session = {
                            SessionStartTime: null,
                            ResultAvailable: null,
                            ShowNotifications: null,
                            EventId: "",
                            SessionId: null,
                            SessionName: "",
                            SessionType: null,
                            CircuitMap: null,
                            TrackLength: null,
                            TrackName: null,
                            Weather: null,
                            Temperature: null,
                            TrackCondition: null,
                            IsTeamEvent: null,
                            MultiCls: null,
                            ns: null,
                            nst: null,
                            bn: null,
                            bst: null,
                            bet: null,
                            officials: {
                                CompetitionSecretary: null,
                                Timekeeper: null,
                            },
                            dp: null,
                            blt: null,
                            bs1: null,
                            bs2: null,
                            bs3: null,
                            tt: null,
                            SD: "10M+1L",
                            flds: [
                                "gd",
                                "ps",
                                "lpe"
                            ],
                            nto: -0.04,
                            ntst: "2022-11-27T15:33:45.329Z",
                            ntip: "132.163.96.2",
                            ntsn: "time.nist.gov",
                            State: null,
                            fcy: false,
                            Sequence: null,
                            Sectors: null,
                            RaceTime: 0,
                            Rd: null,
                            Last: false,
                            End: false,
                            show_race_time: false,
                            TimingSystem: null,
                            Time: null,
                            Date: null,
                            Clear: false,
                            Competitors: [],
                        }
    
                        controls = {
                                AutoCol: false,
                                IncludePs: false,
                                ClockShow: true,
                                TowerShow: true,
                                ResultsShow: false,
                                GridShow: false,
                                NotificationShow: false,
                                FastestLapShow: false,
                                ChampionshipShow: true,
                                ChampionshipSelect: 0,
                                ComparisonShow: false,
                                ComparisonLeadDriver: undefined,
                                TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                ResultsMargin: 0,
                                JumboResultsMargin: 0,
                                GridsMargin: 20,
                                JumboGridsMargin: 0,
                                TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                CurrentPage: 1,
                                BattleShow: false,
                                BattleDrivers: [],
                                BattlePosition: undefined,
                                ProgressionShow: false,
                                ProgressionPosition: undefined,
                                Col4: "gap",
                                Expand: false,
                                ExpandDriverID: undefined,
                                LiveChampsTitle: "LIVE CHAMPIONSHIP",
                                LiveChampsDisplay: "liveChampHide",
                        }
                    }
                    //Session information
                    session.SessionName = data.message.RaceName || null
                    session.SessionId = data.message.RegNum || null

                    var practice = session.SessionName.toUpperCase().indexOf("PRACTICE")
                    var qualifying = session.SessionName.toUpperCase().indexOf("QUALIFYING")
                    var warm = session.SessionName.toUpperCase().indexOf("WARM-UP")
                    var up = session.SessionName.toUpperCase().indexOf("WARM UP")
                    var warmup = session.SessionName.toUpperCase().indexOf("WARMUP")
                    var superPole = session.SessionName.toUpperCase().indexOf("SUPER POLE")
                    
                    if (practice == -1 && qualifying == -1 && warmup == -1 && superPole == -1 && warm == -1 && up == -1){
                        this.setState({
                            SessionType: "Race"
                        })
                    } else {
                        this.setState({
                            SessionType: "Qual"
                        })
                    }

                    if(this.state.SessionType == "Qual"){
                        session.SessionType = "Qual"
                    } else {
                        session.SessionType = "Race"
                    }

                    this.setState({
                        selectedRaceName: data.message.RaceName
                    })
                    
                    break
                case '$C':
                    //category information - Need to fix the class colour options so that it autogenerates, similar to the HS code.
                    if(session.MultiCls){
                        var index = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNum);
                        if (index > -1){
                            session.MultiCls[index].ClassName = data.message.ClassName
                            session.MultiCls[index].ClassNum = data.message.ClassNum
                        } else {
                            var objCategory = {
                            ClassName: data.message.ClassName,
                            ClassNum: data.message.ClassNum,
                            nbc: this.state.ClientStates.Nbc,
                            nfc: this.state.ClientStates.Nfc
                            }
                            session.MultiCls.push(objCategory)
                        }
                        
                    } else {
                        var objCategory = {
                            ClassName: data.message.ClassName,
                            ClassNum: data.message.ClassNum,
                            nbc: this.state.ClientStates.Nbc,
                            nfc: this.state.ClientStates.Nfc
                        }

                        session.MultiCls = [objCategory]
                    }
                    break
                case "$E":
                    //session information
                    if(data.message.Setting == "TRACKLENGTH"){
                        session.TrackLength = data.message.Value
                    }

                    if(data.message.Setting == "TRACKNAME"){
                        session.TrackName = data.message.Value 
                    }
                    break
                case "$G":
                    //position and lap information
                    if(session){
                        if(session.Competitors){
                        var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                        session.Competitors[index].NumberOfLaps = data.message.Laps
                        if (session.Competitors[index].Position == 999){
                            session.Competitors[index].GridPosition = parseInt(data.message.Position)
                        }
                        if(session.SessionType == 'Race'){
                            session.Competitors[index].Position = parseInt(data.message.Position)
                        }
                        
                        }
                    }
                    break
                case "$H":
                    //practice/qualifying information
                    if(session){
                        if(session.Competitors){
                            var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                            
                            if(index != -1 && session.SessionType == "Qual"){
                               session.Competitors[index].Position = parseInt(data.message.Position) 

                               if (session.Competitors[index].Position == 1 ){

                               } else {

                               }


                               session.Competitors[index].BestLapNumber = data.message.BestLap
                               session.Competitors[index].BestLaptime = rMonitorTimeStamp(data.message.BestLapTime)
                               
                               if(session.blt == null){
                                if(session){
                                    session.blt = {
                                        cn: session.Competitors[index].CompetitorNumber,
                                        cnpb: session.Competitors[index].nbc,
                                        cnpf: session.Competitors[index].nfc,
                                        ln: session.Competitors[index].BestLapNumber,
                                        tm: session.Competitors[index].BestLaptime,
                                        shown: false
                                    }
                                }
                                
                                } else if (session.blt.tm > session.Competitors[index].BestLaptime){
                                    session.blt = {
                                        cn: session.Competitors[index].CompetitorNumber,
                                        cnpb: session.Competitors[index].nbc,
                                        cnpf: session.Competitors[index].nfc,
                                        ln: session.Competitors[index].BestLapNumber,
                                        tm: session.Competitors[index].BestLaptime,
                                        shown: false
                                    }
                                }
                            } else if (index != -1){
                                if(session.blt == null){
                                    if(session){
                                        session.blt = {
                                            cn: session.Competitors[index].CompetitorNumber,
                                            cnpb: session.Competitors[index].nbc,
                                            cnpf: session.Competitors[index].nfc,
                                            ln: session.Competitors[index].BestLapNumber,
                                            tm: session.Competitors[index].BestLaptime,
                                            shown: false
                                        }
                                    }
                                    
                                    } else if (session.blt.tm > session.Competitors[index].BestLaptime){
                                        session.blt = {
                                            cn: session.Competitors[index].CompetitorNumber,
                                            cnpb: session.Competitors[index].nbc,
                                            cnpf: session.Competitors[index].nfc,
                                            ln: session.Competitors[index].BestLapNumber,
                                            tm: session.Competitors[index].BestLaptime,
                                            shown: false
                                        }
                                    }
                            } else {

                            }
                        }
                    }

                    break
                case "$I":
                    break
                case "$J":
                    //lap passing information
                    if(session){
                        if(session.Competitors){
                            var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                            session.Competitors[index].LastLaptime = rMonitorTimeStamp(data.message.LapTime);
                            session.Competitors[index].RunningTime = rMonitorTimeStamp(data.message.TotalTime);

                            var indexLeader = session.Competitors.findIndex(x => x.Position == "1");
                            // session.Competitors[index].CompetitorNumber = indexLeader;
                            var indexPosForward = session.Competitors.findIndex(x => x.Position == session.Competitors[index].Position - 1);

                            if(session.Competitors[index].Position != 1){
                            //Calculate Time Behind leader
                                if(indexLeader > -1 && session.Competitors[indexLeader].RunningTime != null && session.SessionType == 'Race'){
                                    if(session.Competitors[index].NumberOfLaps != session.Competitors[indexLeader].NumberOfLaps){
                                        console.log("Here")
                                        session.Competitors[index].Behind = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexLeader].RunningTime)
                                    } else {
                                        // session.Competitors[index].Behind = parseInt(session.Competitors[indexLeader].NumberOfLaps) - parseInt(session.Competitors[index].NumberOfLaps)
                                        session.Competitors[index].Behind = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexLeader].RunningTime)
                                        // if (session.Competitors[index].Behind == 'NaN'){
                                        //     session.Competitors[index].Behind = ""
                                        // } else if (session.Competitors[index].Behind > 9){
                                        //     session.Competitors[index].Behind = "+"+session.Competitors[index].Behind + "L"
                                        // } else if (session.Competitors[index].Behind > 1) {
                                        //     session.Competitors[index].Behind = session.Competitors[index].Behind + " Laps"
                                        // } else {
                                        //     session.Competitors[index].Behind = session.Competitors[index].Behind + " Lap"
                                        // }
                                    }
                                    
                                } else {
                                    session.Competitors[index].Behind = ""
                                }
                                
                                //Calculate Behind pos infront
                                if(indexPosForward > -1 && session.Competitors[indexPosForward].RunningTime != null && session.SessionType == 'Race'){
                                    if(session.Competitors[index].NumberOfLaps != session.Competitors[indexPosForward].NumberOfLaps){
                                        session.Competitors[index].Gap = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexPosForward].RunningTime)
                                    } else {
                                        // session.Competitors[index].Gap = parseInt(session.Competitors[indexPosForward].NumberOfLaps) - parseInt(session.Competitors[index].NumberOfLaps)
                                        session.Competitors[index].Gap = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexPosForward].RunningTime)
                                        // if (session.Competitors[index].Gap == 'NaN'){
                                        //     session.Competitors[index].Behind = ""
                                        // } else if (session.Competitors[index].Gap > 9){
                                        //     session.Competitors[index].Gap = "+"+session.Competitors[index].Gap + "L"
                                        // } else if (session.Competitors[index].Gap > 1) {
                                        //     session.Competitors[index].Gap = session.Competitors[index].Gap + " Laps"
                                        // } else {
                                        //     session.Competitors[index].Gap = session.Competitors[index].Gap + " Lap"
                                        // }
                                    }
                                } else {
                                    session.Competitors[index].Gap = ""
                                } 
                            } else {
                                session.Competitors[index].Behind = ""
                                session.Competitors[index].Gap = ""
                            }
                        }
                    }
                    
                    

                    break
                case "$F":
                console.log(data.message.FlagStatus)
                    if(data.message.FlagStatus == "Yellow"){
                        session.fcy = true
                    } else {
                        session.fcy = false
                    }

                    if(data.message.FlagStatus == "Red   "){
                        session.State = "RedFlag"
                    } else if (data.message.FlagStatus == "Green ") {
                        session.State = "Running"
                    } else if (data.message.FlagStatus == "Finish") {
                        session.State = "ChequeredFlag"
                    }

                    //Remaining Duration
                    if (data.message.LapsToGo == 9999){
                        session.SD = "rMonitor - Time"
                        if(this.state.Session){
                            if(this.state.Session.SessionName){
                                var Qualifying = this.state.Session.SessionName.indexOf("Qualifying")
                            } else {
                                var Qualifying = -1
                            }
                        } else {
                            var Qualifying = -1
                        }
                        

                        if(Qualifying != -1){
                            this.setState({
                                timeRemaining: data.message.RaceTime
                            })
                        } else {
                            this.setState({
                                timeRemaining: data.message.TimeToGo
                            })
                        }

                        
                    } else {
                        session.SD = "rMonitor - Laps"
                        this.setState({
                            timeRemaining: data.message.LapsToGo
                        })
                    }
                    
                    break
                case "COMP":
                    if(session.Competitors){
                        // console.log(session.Competitors)
                        // console.log(session.MultiCls)
                        var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                        if(data.message.AdditionalData){
                            data.message.AdditionalData = data.message.AdditionalData.replace('/ ', '')
                        }
                        if (index > -1){
                            if(data.message.FirstName){
                                var name = data.message.FirstName + " " + data.message.LastName
                            } else {
                                var name = data.message.LastName
                            }
                            session.Competitors[index].CompetitorClass = data.message.ClassNum
                            session.Competitors[index].CompetitorName = name
                            session.Competitors[index].nat = data.message.Nationality || session.Competitors[index].nat
                            session.Competitors[index].CompetitorNumber = data.message.Number
                            session.Competitors[index].TeamName = data.message.AdditionalData || session.Competitors[index].TeamName
                        } else {
                            //get category info
                        var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);
                        if(data.message.FirstName){
                            var name = data.message.FirstName + " " + data.message.LastName
                        } else {
                            var name = data.message.LastName
                        }
                        if(catIndex){
                            var objComp = {
                                "CompetitorId": data.message.RegNum,
                                "CompetitorNumber": data.message.Number,
                                "CompetitorName": name,
                                "CompetitorClass": data.message.ClassNumber,
                                "CompetitorSubClass": "",
                                "DriverName": null,
                                "Position": 999,
                                "NumberOfLaps": 0,
                                "LastLaptime": null,
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "IsCurrentLapPBestLap": false,
                                "Seen": true,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "PositionChange": 0,
                                "Gap": null,
                                "gd": null,
                                "Behind": null,
                                "Split1": null,
                                "Split2": null,
                                "Split3": null,
                                "IsBestSector1": false,
                                "pbs1": false,
                                "IsBestSector2": false,
                                "pbs2": false,
                                "IsBestSector3": false,
                                "pbs3": false,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": false,
                                "blnr": false,
                                "tt": null,
                                "nat": null,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": session.MultiCls[catIndex].nbc,
                                "nfc": session.MultiCls[catIndex].nfc,
                                "es1": 18810,
                                "es2": 33700,
                                "TeamName": data.message.AdditionalData
                              }
                        } else {
                            var objComp = {
                                "CompetitorId": data.message.RegNum,
                                "CompetitorNumber": data.message.Number,
                                "CompetitorName": name,
                                "CompetitorClass": data.message.ClassNumber,
                                "CompetitorSubClass": "",
                                "DriverName": null,
                                "Position": 999,
                                "NumberOfLaps": 0,
                                "LastLaptime": null,
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "IsCurrentLapPBestLap": false,
                                "Seen": true,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "PositionChange": 0,
                                "Gap": null,
                                "gd": null,
                                "Behind": null,
                                "Split1": null,
                                "Split2": null,
                                "Split3": null,
                                "IsBestSector1": false,
                                "pbs1": false,
                                "IsBestSector2": false,
                                "pbs2": false,
                                "IsBestSector3": false,
                                "pbs3": false,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": false,
                                "blnr": false,
                                "tt": null,
                                "nat": null,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": '#feff0c',
                                "nfc": '#000000',
                                "es1": 18810,
                                "es2": 33700,
                                "TeamName": data.message.AdditionalData
                              }
                        }
                        

                            session.Competitors.push(objComp)
                        }
                    } else {
                        //get category info
                        var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);
                        if(data.message.FirstName){
                            var name = data.message.FirstName + " " + data.message.LastName
                        } else {
                            var name = data.message.LastName
                        }
                        var objComp = {
                            "CompetitorId": data.message.RegNum,
                            "CompetitorNumber": data.message.Number,
                            "CompetitorName": name,
                            "CompetitorClass": data.message.ClassNumber,
                            "CompetitorSubClass": "",
                            "DriverName": null,
                            "Position": 999,
                            "NumberOfLaps": 0,
                            "LastLaptime": null,
                            "RaceTime": null,
                            "RunningTime": null,
                            "BestLaptime": null,
                            "BestLapNumber": null,
                            "IsBestLapInRace": false,
                            "IsCurrentLapPBestLap": false,
                            "Seen": true,
                            "InPit": false,
                            "Retired": false,
                            "GridPosition": 999,
                            "PositionChange": 0,
                            "Gap": null,
                            "gd": null,
                            "Behind": null,
                            "Split1": null,
                            "Split2": null,
                            "Split3": null,
                            "IsBestSector1": false,
                            "pbs1": false,
                            "IsBestSector2": false,
                            "pbs2": false,
                            "IsBestSector3": false,
                            "pbs3": false,
                            "cs": null,
                            "el": null,
                            "TakenChequered": false,
                            "ocl": null,
                            "clnr": false,
                            "blnr": false,
                            "tt": null,
                            "nat": null,
                            "ps": 0,
                            "lpe": 0,
                            "nbc": session.MultiCls[catIndex].nbc,
                            "nfc": session.MultiCls[catIndex].nfc,
                            "es1": 18810,
                            "es2": 33700
                          }

                        session.Competitors = [objComp]

                    }
                    break
                case "COR":
                    break
                }
            }

        }

        // console.log(session)
        this.setState({
            Controls: controls,
            Session: session
        })
        } else {
            console.log("Dupe")
        }
        // console.log(data)
    })
    console.log(alpha)
    if (alpha == "true"){
        console.log("initial state")
        this.getInitialState()
        setInterval(() => this.clockOn(), 500);

        this.pusher = new Pusher(alphaPusher.key, {
        app_id: alphaPusher.app_id,
        cluster: alphaPusher.host,
        secret: alphaPusher.secret,
        encrypted: true
        })

        this.channel = this.pusher.subscribe(series+"prod")
        this.channel2 = this.pusher.subscribe(series+"prod-notifications")
        this.channel.bind("update", this.messageEventHandler)
        this.channel.bind("new_session", this.messageEventHandler)
        this.channel.bind("clear", this.messageEventHandler)
        this.channel2.bind("update", this.notificationHandler)
    }
  }

    componentWillUnmount() {
    socket.disconnect()
  }

  messageEventHandler = data => {
    var session = this.state.Session
    var controls = this.state.Controls
    // console.log(data)
    if (data.Clear == true){
        console.log("DataClear")
    }

    if (data.blt){
        data.blt.pusher = true
        session.blt = data.blt
    }

    if (data.End == true){
        session.End = true
    }

    if (data.Last == true){

    }

    if (data.RaceTime){
        session.RaceTime = parseInt(data.RaceTime)*1000
    }

    if (data.Rd){
        var index = data.Rd.indexOf("L")
        if (index > -1){
            session.Rd = 0
        } else if (data.Rd == "0") {
            session.Rd = "0"
        } else {
            session.Rd = parseInt(data.Rd)*1000+""
        }

        if(data.Rd == '1L'){
            session.Last = true
        } else {
            session.Last = false
        }
    }

    if (data.SD){
        session.SD = data.SD
    }

    if (data.SessionName){
        session.SessionName = data.SessionName
    }

    if (data.Sectors){
        
    }

    if (data.Sequence){
        
    }

    if (data.TimingSystem){
        
    }

    if (data.State){
        session.State = data.State
    }

    if (data.fcy == true){
        session.fcy = true
    } else if (data.fcy == false) {
        session.fcy = false
    }

    if (data.show_race_time == true){
        
    }

    if (data.SessionId){
        if (data.SessionId != session.SessionId){
            this.getInitialState()
            controls.ResultsMargin = -100
            controls.JumboResultsMargin = -100
            controls.ComparisonLeadDriver = undefined
        } else {
            session.SessionId = data.SessionId
        }
    }

    if (data.Competitors){
        var i = 0
        var competitorsState = session.Competitors
        while (i < data.Competitors.length){
            var competitorIndex = competitorsState.findIndex( x => x.CompetitorId === data.Competitors[i].CompetitorId)
            if (competitorIndex == -1){
                competitorsState.push(data.Competitors[i])
            } else {
                //positionChangeCalcs
                if (data.Competitors[i].Position != undefined){
                    var PosChangeCalc = parseInt(competitorsState[competitorIndex].Position) - parseInt(data.Competitors[i].Position)
                    // console.log("pos", PosChangeCalc, competitorsState[competitorIndex].Position, data.Competitors[i].Position)
                    if (PosChangeCalc == 0){
                        // var PosChangeClass = "positionneutral"
                    } else if (PosChangeCalc > 0){
                        var PosChangeClass = "positionup"
                    } else if (PosChangeCalc < 0){
                        var PosChangeClass = "positiondown"
                    } else {
                        // var PosChangeClass = "positionneutral"
                    } 
                    var posChangeTime = this.getTimestampInSeconds()
                } else {
                    // var PosChangeClass = "positionneutral"
                }
                // console.log(competitorsState[competitorIndex].CompetitorName, data.Competitors[i].IsBestLapInRace)
                competitorsState[competitorIndex] = {
                    "CompetitorId": data.Competitors[i].CompetitorId || competitorsState[competitorIndex].CompetitorId,
                    "CompetitorNumber": data.Competitors[i].CompetitorNumber || competitorsState[competitorIndex].CompetitorNumber,
                    "CompetitorName": data.Competitors[i].CompetitorName || competitorsState[competitorIndex].CompetitorName,
                    "CompetitorClass": data.Competitors[i].CompetitorClass || competitorsState[competitorIndex].CompetitorClass,
                    "CompetitorSubClass": data.Competitors[i].CompetitorSubClass || competitorsState[competitorIndex].CompetitorSubClass,
                    "Chassis": data.Competitors[i].Chassis || competitorsState[competitorIndex].Chassis,
                    "DriverName": data.Competitors[i].DriverName || competitorsState[competitorIndex].DriverName,
                    "Engine": data.Competitors[i].Engine || competitorsState[competitorIndex].Engine,
                    "Position": data.Competitors[i].Position || competitorsState[competitorIndex].Position,
                    "NumberOfLaps": data.Competitors[i].NumberOfLaps || competitorsState[competitorIndex].NumberOfLaps,
                    "LastLaptime": data.Competitors[i].LastLaptime || competitorsState[competitorIndex].LastLaptime,
                    "LapData": competitorsState[competitorIndex].LapData,
                    "RaceTime": data.Competitors[i].RaceTime || competitorsState[competitorIndex].RaceTime,
                    "RunningTime": data.Competitors[i].RunningTime || competitorsState[competitorIndex].RunningTime,
                    "BestLaptime": data.Competitors[i].BestLaptime || competitorsState[competitorIndex].BestLaptime,
                    "BestLapNumber": data.Competitors[i].BestLapNumber || competitorsState[competitorIndex].BestLapNumber,
                    "IsBestLapInRace": data.Competitors[i].IsBestLapInRace || competitorsState[competitorIndex].IsBestLapInRace,"Seen": data.Competitors[i].Seen || competitorsState[competitorIndex].Seen,
                    "InPit": data.Competitors[i].InPit || false,
                    "Retired": data.Competitors[i].Retired || competitorsState[competitorIndex].Retired,
                    "GridPosition": data.Competitors[i].GridPosition || competitorsState[competitorIndex].GridPosition,
                    "PositionChange": data.Competitors[i].PositionChange || competitorsState[competitorIndex].PositionChange || 0,
                    "PosChangeClass" : PosChangeClass || competitorsState[competitorIndex].PosChangeClass || "positionneutral",
                    "posChangeTime" : posChangeTime || competitorsState[competitorIndex].posChangeTime || 0,
                    "Gap": data.Competitors[i].Gap || competitorsState[competitorIndex].Gap,
                    "gd": data.Competitors[i].gd || competitorsState[competitorIndex].gd,
                    "Behind": data.Competitors[i].Behind || competitorsState[competitorIndex].Behind,
                    "Split1": data.Competitors[i].Split1 || competitorsState[competitorIndex].Split1,
                    "Split2": data.Competitors[i].Split2 || competitorsState[competitorIndex].Split2,
                    "Split3": data.Competitors[i].Split3 || competitorsState[competitorIndex].Split3,
                    "TeamName": data.Competitors[i].TeamName || competitorsState[competitorIndex].TeamName,
                    "IsBestSector1": data.Competitors[i].IsBestSector1 || competitorsState[competitorIndex].IsBestSector1,
                    "pbs1": data.Competitors[i].pbs1 || competitorsState[competitorIndex].pbs1,
                    "IsBestSector2": data.Competitors[i].IsBestSector2 || competitorsState[competitorIndex].IsBestSector2,
                    "pbs2": data.Competitors[i].pbs2 || competitorsState[competitorIndex].pbs2,
                    "IsBestSector3": data.Competitors[i].IsBestSector3 || competitorsState[competitorIndex].IsBestSector3,
                    "pbs3": data.Competitors[i].pbs3 || competitorsState[competitorIndex].pbs3,
                    "cs": data.Competitors[i].cs || competitorsState[competitorIndex].cs,
                    "el": data.Competitors[i].el || competitorsState[competitorIndex].el,
                    "TakenChequered": data.Competitors[i].TakenChequered || competitorsState[competitorIndex].TakenChequered,
                    "ocl": data.Competitors[i].ocl || competitorsState[competitorIndex].ocl,
                    "clnr": data.Competitors[i].clnr || competitorsState[competitorIndex].clnr,
                    "blnr": data.Competitors[i].blnr || competitorsState[competitorIndex].blnr,
                    "tt": data.Competitors[i].tt || competitorsState[competitorIndex].tt,
                    "nat": data.Competitors[i].nat || competitorsState[competitorIndex].nat,
                    "ps": data.Competitors[i].ps || competitorsState[competitorIndex].ps,
                    "lpe": data.Competitors[i].lpe || competitorsState[competitorIndex].lpe,
                    "nbc": data.Competitors[i].nbc || competitorsState[competitorIndex].nbc,
                    "nfc": data.Competitors[i].nfc || competitorsState[competitorIndex].nfc,
                    "es1": data.Competitors[i].es1 || competitorsState[competitorIndex].es1,
                    "es2": data.Competitors[i].es2 || competitorsState[competitorIndex].es2
                }

                if (data.Competitors[i].IsBestLapInRace == false && competitorsState[competitorIndex].IsBestLapInRace == true){
                    competitorsState[competitorIndex].IsBestLapInRace = false
                } else if (data.Competitors[i].IsBestLapInRace == undefined && competitorsState[competitorIndex].IsBestLapInRace == true){
                    competitorsState[competitorIndex].IsBestLapInRace = true
                } else {
                    competitorsState[competitorIndex].IsBestLapInRace = data.Competitors[i].IsBestLapInRace
                } 

                if (data.Competitors[i].LastLaptime){
                    var newLapObj = {
                        "LapNo": competitorsState[competitorIndex].NumberOfLaps,
                        "LapTime": data.Competitors[i].LastLaptime*1000
                    }
                    if (competitorsState[competitorIndex].LapData){
                        competitorsState[competitorIndex].LapData.push(newLapObj)
                    } else {
                        competitorsState[competitorIndex].LapData = []
                        competitorsState[competitorIndex].LapData.push(newLapObj)
                    }
                }
                
            }
            i++
        }
        session.Competitors = competitorsState
    }

    if (data.SessionStartTime){
        session.SessionStartTime = data.SessionStartTime
    }

    this.setState({
        Session: session,
        Controls: controls
    })
    }
    
    notificationHandler = data => {
        // console.log(data)
        var notifications = this.state.Notifications
        //Check exists START
        if (data.m[0]){
            var index = notifications.findIndex(x => x[0].id == data.m[0].id);
        } else {
            var index = 1
        }
            
        //Check exists ENDS
        
        if (index == -1){
            this.setState({
                NotificationSequence: data.s
            })
            if (data.m.length > 0){
                if (data.m[0].nt == 1 || data.m[0].nt == 2 ||data.m[0].nt == 3 ||data.m[0].nt == 4 ||data.m[0].nt == 5 ||data.m[0].nt == 6 ||data.m[0].nt == 9 ||data.m[0].nt == 10 ||data.m[0].nt == 27 ||data.m[0].nt == 28 ||data.m[0].nt == 36 ||data.m[0].nt == 37){
                    if(data.m[0].t){
                        data.m[0].t = " - " + data.m[0].t
                    } else {
                        data.m[0].t = ""
                    }
                    switch(data.m[0].nt){
                        case 1: 
                            data.m[0].nt = "Cone warning";
                            data.m[0].displayed = false
                            break
                        case 2: 
                            data.m[0].nt = "Kerb warning";
                            data.m[0].displayed = false
                            break
                        case 3: 
                            data.m[0].nt = "Contact warning";
                            data.m[0].displayed = false
                            break
                        case 4: 
                            data.m[0].nt = "Warning";
                            data.m[0].displayed = false
                            break
                        case 5: 
                            data.m[0].nt = "Black flag";
                            data.m[0].displayed = false
                            break
                        case 6: 
                            data.m[0].nt = "Cone Penalty";
                            data.m[0].displayed = false
                            break
                        case 9: 
                            data.m[0].nt = "Penalty";
                            data.m[0].displayed = false
                            break
                        case 10: 
                            data.m[0].nt = "Mechanical flag";
                            data.m[0].displayed = false
                            break
                        case 27: 
                            data.m[0].nt = "Knob warning";
                            data.m[0].displayed = false
                            break
                        case 28: 
                            data.m[0].nt = "Visor warning";
                            data.m[0].displayed = false
                            break
                        case 36: 
                            data.m[0].nt = "Drive through penalty";
                            data.m[0].displayed = false
                            break
                        case 37: 
                            data.m[0].nt = "Track limit warning";
                            data.m[0].displayed = false
                            break
                        case 101: 
                            data.m[0].nt = "Red Flag";
                            data.m[0].displayed = true
                            break
                        case 102: 
                            data.m[0].nt = "Restart"
                            data.m[0].displayed = true
                            break
                        case 103: 
                            data.m[0].nt = "Race Start";
                            data.m[0].displayed = true
                            break
                        case 104: 
                            data.m[0].nt = "False Start";
                            data.m[0].displayed = true
                            break
                        case 105: 
                            data.m[0].nt = "Chequered Flag";
                            data.m[0].displayed = true
                            break
                        case 107: 
                            data.m[0].nt = "Full Course Yellow";
                            data.m[0].displayed = true
                            break
                        case 108: 
                            data.m[0].nt = "Full Course Yellow END";
                            data.m[0].displayed = true
                            break
                        case 109:
                            data.m[0].nt = data.m[0].nt
                            data.m[0].displayed = true
                    }
                    notifications.push(data.m)
                    this.setState({
                        Notifications: notifications,
                    })
                }
                
            }
        }
    }

    getInitialState = () => {
        request("https://live.alphatiming.co.uk/"+series+".json", (error, response, json) => {
            var json = JSON.parse(json)
            json.Rd = json.Rd*1000+""
            var controls = {
                AutoCol: false,
                IncludePs: false,
                ClockShow: true,
                TowerShow: true,
                ResultsShow: false,
                NotificationShow: false,
                FastestLapShow: false,
                ChampionshipShow: true,
                ChampionshipSelect: 0,
                ComparisonShow: false,
                ComparisonLeadDriver: undefined,
                TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                ResultsMargin: -100,
                JumboResultsMargin: -100,
                TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                CurrentPage: 0,
                BattleShow: false,
                BattleDrivers: [],
                BattlePosition: undefined,
                ProgressionShow: false,
                ProgressionPosition: undefined,
                Col4: "gap",
            }

            this.setState({
                Session:json,
                Controls:controls,
                Notifications : [],
                NotificationSequence : undefined
            })
        })
    }

  msRacetime = (ms) => {
    //Converts ms to Alpha style race duration
    var milli = ms
    var seconds = Math.floor((milli / 1000) % 60)
    var minutes = Math.floor((milli / (1000 * 60)) % 60)
    var hours = Math.floor((milli / (1000 * 60 * 60)) % 24)
  
    if (hours == 0){
        var duration = minutes+"M"
    } else if (hours > 0 && minutes == 0) {
        var duration = hours+"H"
    } else {
        var duration = hours + "H" + minutes + "M"
    }
    return duration
  }
  
  rMonitorTimeStamp = (timestamp) => {
    const [hours, minutes, secondsWithMs] = timestamp.split(':');
    const [seconds, milliseconds] = secondsWithMs.split('.');

    return (
        parseInt(hours, 10) * 60 * 60 * 1000 +
        parseInt(minutes, 10) * 60 * 1000 +
        parseInt(seconds, 10) * 1000 +
        parseInt(milliseconds, 10)
    );
  }

  setChampCode = (champCode) => {
    console.log(champCode)
    this.setState({champCode: champCode})
    this.setState({year: champCode})
  }

  getMeetings = () => {
    request("https://results.alphatiming.co.uk/api/v1/"+series+"/2024/events", (error, response, body) => {
        
        if(error){
            console.error('error:', error); // Print the error if one occurred
        } 
        var meetings = JSON.parse(body)
        console.log(meetings.events)

        this.setState({meetings: meetings.events}) 
        
        if (!this.state.selectedMeeting){
          this.setState({selectedMeeting: this.state.meetings[0].sessionsLink})
        }

        this.getRaceList(this.state.selectedMeeting)
    });
  }

  getRaceList = (selectedMeeting, fromSocket) => {
    
    if(fromSocket == undefined){    
        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: "getRaceList",
            message2: selectedMeeting,
            time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };

        socket.emit("send_message", messageData); 
    }
    
    this.setState({selectedMeeting: selectedMeeting})
    console.log("getting Races")


      request(selectedMeeting, (error, response, body) => {
          
          if(error){
              console.error('error:', error); // Print the error if one occurred
          } 
          var races = JSON.parse(body)
          console.log(races.sessions)

          var i = 0
          var raceList = []

          while (i< races.sessions.length){
              if (races.sessions[i].sessionType == "Timed Qualifying" || races.sessions[i].sessionType == "Practice"){

              } else {
                  raceList.push(races.sessions[i])
              }
              i++
          }

          function sortByDate( a, b ) {
            if ( a.startDate < b.startDate ){
              return -1;
            }
            if ( a.startDate > b.startDate ){
              return 1;
            }
            return 0;
          }
          
          raceList.sort(sortByDate);
          console.log(raceList)
          this.setState({races: raceList})

          if (!this.state.selectedRace){
            this.setState({selectedRace: 0})
          }
          
          this.getGrid(this.state.selectedRace)
      });
  }

  getGrid = (selectedRace, fromSocket) => {

    this.setState({selectedRace: selectedRace})

    if(fromSocket == undefined){    
        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: "getGrid",
            message2: selectedRace,
            time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };

        socket.emit("send_message", messageData); 
    }
  
    if(this.state.races[selectedRace]){
      if(this.state.races[selectedRace].gridLink){
        var gridLink = this.state.races[selectedRace].gridLink

        request(gridLink, (error, response, body) => {
            
            if(error){
                console.error('error:', error); // Print the error if one occurred
            } 
            var grid = JSON.parse(body)
            console.log(grid.grid)

            var raceName
            if(this.state.races[selectedRace].name){
              var raceNameTest = this.state.races[selectedRace].name.indexOf(":")
              console.log(raceNameTest)
              if (raceNameTest != -1){
                var sessionNameArray = this.state.races[selectedRace].name.split(":")
                raceName = sessionNameArray[1]
              } else {
                raceName = this.state.races[selectedRace].name
              }

              this.setState({selectedRaceName: raceName.trim()})
              this.setState({grid: grid.grid})
            } else {

            }
        });
      }
    }
    
  }

  changeHead = (e, fromSocket) => {
    
    if(fromSocket == undefined){    
        console.log(e)

        this.setState({
            selectedHeader: e
        })
        
        var messageData = {
                room: room,
                author: "Controller",
                type: "Graphics",
                message: "changeHeader",
                message2: e,
                time:
                    new Date(Date.now()).getHours() +
                    ":" +
                    new Date(Date.now()).getMinutes(),
                };

        socket.emit("send_message", messageData); 
    } else {
        this.setState({
            selectedHeader: e
        })
    }

    
  }

  nextRace = () => {
      var select = document.getElementById("race");
      if(select.selectedIndex == select.length-1){
        return
      } else {
        document.getElementById("race").selectedIndex++;
        this.getGrid(document.getElementById("race").selectedIndex)
      }

  }

  render() {
    return <div>
      <Router>
        <Switch>
            <Route exact path ="/jumbo" render={(props) => <Jumbo  {...this.state} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} battleCalc={this.battleCalc.bind(this)}/>}/>
            <Route exact path ="/graphics" render={(props) => <Graphics  {...this.state} changeHead={this.changeHead.bind(this)} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} battleCalc={this.battleCalc.bind(this)}/>}/>
            <Route exact path ="/" render={(props) => <ControllerPage {...this.state} changeHead={this.changeHead.bind(this)} sendProgression={this.sendProgression.bind(this)} handleCheckBox={this.handleCheckBox.bind(this)} changeSessionName={this.changeSessionName.bind(this)} changeTickerName={this.changeTickerName.bind(this)} changeDuration={this.changeDuration.bind(this)} urlSend={this.urlSend.bind(this)} submitClientStates={this.submitClientStates.bind(this)} controllerImageCheck={this.controllerImageCheck.bind(this)} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} getGrid={this.getGrid.bind(this)} getRaceList={this.getRaceList.bind(this)}/>}/>
            <Route exact path ="/table" render={(props) => <Table  {...this.state} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} battleCalc={this.battleCalc.bind(this)}/>}/>
        </Switch>
      </Router>
    </div>
  }

    handleCheckBox = (e) => {
        console.log(e.target.checked)

        //Handle session type change to timed session
        if(alpha != true){
            var session = this.state.Session
            if(e.target.checked == true){
                var sessionType = 'Qual'
            } else {
                var sessionType = 'Race'
            }

            session.SessionType = sessionType

            this.setState({
                Session: session,
                SessionType: sessionType
            })
        }
        
        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: "SessionType",
            message2: sessionType,
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };
    
        socket.emit("send_message", messageData); 

    }

    changeSessionName(e) {
    var doc = e.target.parentNode

    var name = doc.querySelector('#sessionName').value

    this.setState({
        selectedRaceName: name
    })

    var messageData = {
        room: room,
        author: "Controller",
        type: "Graphics",
        message: "SessionName",
        message2: name,
        time:
        new Date(Date.now()).getHours() +
        ":" +
        new Date(Date.now()).getMinutes(),
    };

    socket.emit("send_message", messageData); 
    }

    changeTickerName(e) {
        var doc = e.target.parentNode

        var name = doc.querySelector('#tickerName').value

        this.setState({
            TickerName: name
        })

        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: "TickerName",
            message2: name,
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        socket.emit("send_message", messageData); 
    }
  
    changeDuration(e) {
        var doc = e.target.parentNode

        var laps = doc.querySelector('#number').value

        this.setState({
            RaceDuration: laps
        })

        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: "RaceDuration",
            message2: laps,
            time:
              new Date(Date.now()).getHours() +
              ":" +
              new Date(Date.now()).getMinutes(),
          };

        socket.emit("send_message", messageData); 
    }

    sendProgression = (e) => {
        var doc = e.target.parentNode

        var progressPositions = doc.querySelector('#Advance').value

        if(progressPositions){
                var messageData = {
                room: room,
                author: "Controller",
                type: "Graphics",
                message: e.target.getAttribute("attr1"),
                message2: parseInt(progressPositions),
                time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };

            socket.emit("send_message", messageData);
        }

        
    }

    submitClientStates = (e) => {
    var obj = {
        CountryFlags: document.getElementById("Flags").checked,
        DefaultProgression: document.getElementById("Advance").value,
        DriverPhotos: document.getElementById("Photos").checked,
        FlSponsor: document.getElementById("flSponsor").checked,
        PrimaryColour: "",
        ResultsSponsor: document.getElementById("resultSponsor").checked,
        SecondaryColour: "",
        TimingName: document.getElementById("TimingName").value,
        TimingSystem: document.getElementById("TimingSystem").value,
        TowerHeadHeight: document.getElementById("TowerHeight").value + "px",
        TowerSponsor: document.getElementById("towerSponsor").checked,
        TowerSponsorHeight: document.getElementById("TowerSponsorHeight").value + "px",
        TowerSponsorLogo: document.getElementById("TowerLogo").value,
        TowerSponsorWidth: document.getElementById("TowerWidth").value + "px"
    }
    
    this.setState({
        ClientStates: obj
    })

    var messageData = {
        room: room,
        author: "Controller",
        type: "Database",
        message: obj,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };

    socket.emit("send_message", messageData);

    console.log(room,obj)
    }

    imageError = (e) => {
    e.target.src = "/images/Blank.png"
    } 

    controllerImageCheck = (e) => {
    var compId = e.target.getAttribute("attr1")
    console.log("imageError",compId)
    document.getElementById(compId+"Expand").disabled = true
    }

    automaticCol = () => {
    var controls = this.state.Controls
    if (controls.AutoCol == true){
        var col = this.state.Controls.Col4

        if (col == "gap" && controls.IncludePs == true){
            var colData = "pits"
        } else if (col == "gap" ){
            var colData = "diff"
        } else if (col == "pits" ){
            var colData = "diff"
        } else if (col == "diff"){
            var colData = "positions"
        } else if (col == "positions"){
            var colData = "gap"
        } else {
            var colData = "gap"
        }

        controls.Col4 = colData
        this.setState({
            Controls: controls
        })
    } else {
        
    }
    }

    battleCalc = (e) => {
    
    var driverIndex = this.state.Session.Competitors.findIndex(x => x.CompetitorId == e.target.getAttribute("attr2"))
    // console.log(e.target.getAttribute("attr2"), driverIndex)
    var controls = this.state.Controls
    var battleDrivers = controls.BattleDrivers
    var battleIndex = battleDrivers.indexOf(parseInt(this.state.Session.Competitors[driverIndex].CompetitorId))

    if (battleIndex == -1){
       battleDrivers.push(parseInt(this.state.Session.Competitors[driverIndex].CompetitorId))
       var battlePosition = this.state.Controls.BattlePosition
        if (parseInt(battlePosition) > parseInt(this.state.Session.Competitors[driverIndex].Position) || battlePosition == undefined){
            console.log("here")
            controls.BattlePosition = this.state.Session.Competitors[driverIndex].Position
        }

    } else {
        var remove = battleDrivers.splice(battleIndex,1)
        if (parseInt(this.state.Session.Competitors[driverIndex].Position) == parseInt(this.state.Controls.BattlePosition)){
            controls.BattlePosition = undefined
            controls.BattleDrivers = []
        }
    } 

    this.setState({
        Controls: controls
    })
    }

    urlSend = (e) => {
    }

    controlSend = (e) => {
    console.log(e.target.getAttribute("attr1"))
    if (this.state.ClientStates.TimingSystem == "TSL"){
        var room = "TSL"
    } else {
        var room = series
    }

    var messageData = {
        room: room,
        author: "Controller",
        type: "Graphics",
        message: e.target.getAttribute("attr1"),
        message2: e.target.getAttribute("attr2"),
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };

    if(e.target.getAttribute("attr1") == "battleShow"){
        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: e.target.getAttribute("attr1"),
            message2: this.state.Controls.BattlePosition,
            message3: this.state.Controls.BattleDrivers,
            time:
              new Date(Date.now()).getHours() +
              ":" +
              new Date(Date.now()).getMinutes(),
          };
    }

    if(e.target.getAttribute("attr1") == "compare"){
        var controls = this.state.Controls
        controls.ComparisonLeadDriver = e.target.getAttribute("attr2")
        this.setState({
            Controls: controls
        })
    }

    if(e.target.getAttribute("attr1") == "expand"){
        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: e.target.getAttribute("attr1"),
            message2: e.target.getAttribute("attr2"),
            time:
              new Date(Date.now()).getHours() +
              ":" +
              new Date(Date.now()).getMinutes(),
          };
    }

    if(e.target.getAttribute("attr1") == "getMeetings"){
        this.getMeetings()
    }

    if(e.target.getAttribute("attr1") == "getGrid"){
        this.getGrid()
    }

      socket.emit("send_message", messageData); 
    }



    pages = () => {
    var CurrentPage = this.state.Controls.CurrentPage
    var controls = this.state.Controls
    var defaultMargin = [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220]
    if(this.state.Session){
        if(this.state.Session.Competitors){
            var index = this.state.Session.Competitors.findIndex(x => x.NumberOfLaps > 1)
            
            if(CurrentPage != undefined && index != -1){
                if(this.state.Session){
                    
                    const lapsGreaterThanZero = obj => obj.NumberOfLaps > 0;

                    var totCompetitors = this.state.Session.Competitors.filter(lapsGreaterThanZero).length;
                    var numOfPages = Math.ceil((totCompetitors/10)-1)

                    console.log("numOfPages",this.state.Session.Competitors.length,totCompetitors,numOfPages)

                    if(CurrentPage < numOfPages){
                        CurrentPage++
                        controls.CurrentPage = CurrentPage
                        this.setState({
                            Controls: controls
                        })
                    } else {
                        CurrentPage = 1
                        controls.CurrentPage = CurrentPage
                        this.setState({
                            Controls: controls
                        })
                    }
                } else {
                    console.log("no Session")
                }
            } else {
                CurrentPage = 1
                controls.CurrentPage = CurrentPage
                this.setState({
                    Controls: controls
                })
            }
        }
        
    }
    
    }

    msToTime = (timestamp) => {
    function pad(n, z = 2) {
        return ('00' + n).slice(-z);
    }

    if (timestamp === 9223372036854776000) {
        return "";
    }

    let ms = Math.floor(timestamp / 1000) % 1000;
    let seconds = Math.floor(timestamp / (1000 * 1000)) % 60;
    let minutes = Math.floor(timestamp / (1000 * 1000 * 60)) % 60;
    let hours = Math.floor(timestamp / (1000 * 1000 * 60 * 60));

    if (hours === 0 && minutes === 0 && seconds === 0) {
        return "0." + pad(ms, 3)
    } else if (hours === 0 && minutes === 0 && seconds < 10) {
        return seconds + '.' + pad(ms, 3)
    } else if (hours === 0 && minutes === 0) {
        return pad(seconds) + '.' + pad(ms, 3)
    } else if (hours === 0 && minutes < 10) {
        return minutes + ':' + pad(seconds) + '.' + pad(ms, 3)
    } else if (hours === 0) {
        return pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3)
    } else {
        return hours + ':' + pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3)
    }
    }

    clockOn = () => {
        
    if(this.state.Session){
        
        var session = this.state.Session
        if(session.ServerTime && this.state.ClientStates.TimingSystem == "Apex"){
            session.ServerTime = session.ServerTime + 500
        }

        if(session.State == "Running"){
            
            if(session.SD){
                var laps = session.SD.indexOf("Laps")
                
                if(laps > -1){
                    console.log("LAPS")
                    var lapsArray = session.SD.split(" ")

                    var competitorsState = session.Competitors

                    var driverIndex = competitorsState.findIndex( x => x.Position == 1)

                    if(driverIndex > -1){
                        var lapsComplete = competitorsState[driverIndex].NumberOfLaps+1 || 0
                    } else {
                        var lapsComplete = 0
                    }
                    
                    if(lapsComplete == lapsArray[0]){
                        this.setState({
                            timeRemaining: "FINAL LAP"
                            })
                    } else {
                        this.setState({
                            timeRemaining: lapsComplete + "/" + lapsArray[0]
                        })
                    }


                    

                } else {
                    var addLaps = session.SD.indexOf("L")
                if (addLaps > -1){
                    this.setState({
                        additionalLaps: parseInt(session.SD[session.SD.length-2])
                    })
                }

                if(session.Rd){
                   //var index = session.Rd.indexOf("L") 
                    var index = -1
                } else {
                    var index = -1
                }
                
                if (index > -1){

                } else {
                    if (this.state.startTime){
                        //Apex
                        var currentTime = session.ServerTime
                        var millis = currentTime - this.state.startTime

                        var totalDurationMS = parseInt(session.Duration)

                        session.Rd = totalDurationMS-millis+""

                        millis = session.Rd
                        
                    } else {
                        //Alpha
                        var millis = session.Rd-500
                        session.Rd = millis+""
                    }
                    
                    this.setState({
                        Session: session
                    })

                    // console.log("millis",millis)
                    if (millis < 1000 || millis == NaN) {
                    millis = 0
                    }
                    var micro = millis
                    // console.log("micro",micro)

                    if (micro == "NaN" || micro == NaN){
                        micro = 0
                    }

                    this.remainingTime(micro*1000)
                    }
                }

                
            }
        }
        
      }
    }

    tslClockOn = () => {
    // Check if there's a session ongoing
    if(this.state.Session) {
        // Check if the session is in a 'Running' state
        if(this.state.Session.State == "Running") {
            if(this.state.Session.SD){

            }
            // Store the last time count
            var last = this.state.lastTimeCount;

            // Get the current time in milliseconds since 1 January 1970 00:00:00 UTC
            var time = Date.now();

            // Compute the time elapsed since the last time count
            var timeSinceLast = time - last;

            // Get the start time of the session in seconds since 1 January 1970 00:00:00 UTC, rounding up if needed
            var start = Math.ceil(this.state.Session.SessionStartTime/1000);

            // Get the planned length of the session
            var duration = this.state.Session.LengthTime;

            // Get the current time in milliseconds since 1 January 1970 00:00:00 UTC (same as the variable 'time' defined earlier)
            var now = Date.now();

            // Compute how long the session has been running
            var runningTime =  now - start;

            // Compute the remaining time in milliseconds, taking into account a predefined offset of 3600000 milliseconds (1 hour)
            var millis = Math.ceil(duration/1000) - runningTime;
            var millis2 = Math.ceil(millis - 3600000);

            // Ensure that the remaining time cannot be less than 1 second
            if (millis2 < 1000) {
                millis2 = 0;
            }

            // Convert the remaining time to microseconds
            var micro = millis2*1000;

            // Call the function to handle the remaining time and print it to the console
            this.remainingTime(micro);
            // console.log(micro);
        }
}

    }

    remainingTime = (micro) => {
        this.setState({microRemaining: micro})
        // console.log("remainingMicro",micro)
        var milli = micro/1000
        var seconds = Math.floor((milli / 1000) % 60)
        var minutes = Math.floor((milli / (1000 * 60)) % 60)
        var hours = Math.floor((milli / (1000 * 60 * 60)) % 24)
    
        if (seconds < 10){
        seconds = "0"+seconds
        }
    
        if (minutes < 10){
        minutes = "0"+minutes
        }
    

        if (hours == 0 && minutes == 0 && seconds == 0){
            var remaining = "00:00"
        } else {
            if (hours == 0){
                var remaining = minutes + ":" + seconds
            } else {
                var remaining = hours + ":" + minutes + ":" + seconds
            }   
        }

        if(this.state.Session.state == "Ended"){

        } else if(this.state.Session.Rd == "1L"){
            
        } else {
            this.setState({timeRemaining: remaining})
            this.setState({lastTimeCount: Date.now()})
        }
        

    }

    getTimestampInSeconds = () => {
        return Math.floor(Date.now() / 1000)
    }
}

export default App;
