import react , { Component } from "react";
import Timing from './Controller/timing';
import Session from './Controller/session';
import './Controller/stylesheets/session.css';
import BasicControls from './Controller/basicControls';
import ClientState from './Controller/clientState';
const fetch = require("node-fetch");

class ControllerPage extends Component {

    // componentDidMount() {
    //     // var s = "";
    //     // while(s != "a1ph411v3"){
    //     //   s= prompt("please enter your password");
    //     //   if (s=="a1ph411v3"){
    
    //     //   } else {
    //     //     alert("Incorrect Password - Try again")
    //     //   }
    //     // }
    
    //   }
 
    render() {
    if (this.props.Session.Competitors == undefined) {
        return (
            <div>Waiting for data....
            </div>
        )
    } else {
        return (
            <div>
                <div className="DirectorsDiv">
                    
                    <Timing {...this.props}/>
                    <div className="boxContainer">
                        <Session {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <BasicControls {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        {/* <ClientState {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                        {/* <Suggestions {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                    </div>
                    
                </div>
            </div> 
        )}
        }


    handleBattleCommand = (words) => {
        
        // Here you can add your code to process the command
    }

    collapse = (e) => {
        var grabber = e.target.parentNode
        if(grabber.style.height == "30px"){
            grabber.style.height = "150px"
        } else {
            grabber.style.height = "30px"
        }
        
    }

    toggle = () => {
        if (this.state.expand === "expandOn") {
            this.setState({expand: "expandOff"})
        } else {
        this.setState({expand: "expandOn"})
        }
    }

    processDrivers = () => {
        //console.log("processData")
        var fileInput = document.getElementById("raceSelect")
        //console.log(fileInput)
        this.readFile(fileInput)
    }

    readFile = (fileInput) => {
        var reader = new FileReader();
        reader.onload = () => {
            //document.getElementById('out').innerHTML = reader.result;
            this.processCSV(reader.result)
        };
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(fileInput.files[0]);
        //reader.readAsText(fileInput.files[0]);
    };

    clearCSV = () => {
        fetch("http://77.68.119.159:4003/?method=clear&drivers=one")
            .then(response => response.json())
            .then(data => document.getElementById("driversDisplay").innerHTML = JSON.stringify(data));
    }

    processCSV = (csv) => {
        var allTextLines = csv.split("\r\n");
        var lines = [];
        var removedRows = allTextLines.splice(0,2);
        var i = 0
        while (i<allTextLines.length) {
            var data = allTextLines[i]
            var dataArray = data.split(",")
            lines.push(dataArray[0])
            lines.push("")
            lines.push(dataArray[5])
            i++
        }
        
        // var currentData = JSON.stringify(lines);
        console.log("currentData", lines)
        // console.log(currentData)
        document.getElementById("driversDisplay").innerHTML = lines

        this.push("HAND"+lines)

        // fetch("http://77.68.119.159:4003/?method=ADD&drivers="+currentData)
        //     .then(response => response.json())
        //     // .then(data => document.getElementById("driversDisplay").innerHTML = JSON.stringify(data));
    }

    setClock = () => {
        var minutes = document.getElementById("setTimeMins").value
        var seconds = minutes * 60

        fetch("http://77.68.119.159:2001/?message=b"+seconds)

    }

    push = (message) => {
        fetch("http://77.68.119.159:2001/?message="+message)
        
    }

    hsURL = (message) => {
        fetch("http://77.68.119.159:2146/?connect=connect&message="+message)

    }

    expand = (pos) => {
        var heightArray = [];
        var i = 1
        //update to length for production version
        while (i<=20) {
            if (i<pos) {
            heightArray.push("40px")
            } else if (i == pos) {
            heightArray.push("120px")
            } else {
            heightArray.push("40px")
            }
            i++
        }

        var heightString = heightArray.toString()
        fetch("http://77.68.119.159:2001/?message="+heightString)
    }

    connect = (message) => {
        fetch("http://77.68.119.159:2146/?connect="+message)

    }

}

export default ControllerPage;