import React, { PureComponent } from 'react';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let alpha = params.alpha;

class GridItem  extends PureComponent { 
    render() {


        //Opacity Calculations START
        var delay = 100 * this.props.id;
        if (this.props.Controls.GridShow == true < 1){
            var opacity = 0;
            var delay = 200 * parseInt(this.props.id) +1000;
        } else {
            var opacity = 1;
            var delay = 200 * parseInt(this.props.id) +1000;
        }
        
        if (!this.props.driver.Position && !this.props.driver.gridPosition){
            opacity = 0
        }
        //Opacity Calculations END

        //Smart Naming Calculations START
        if(this.props.driver.name || this.props.driver.CompetitorName){
        if(alpha == "true"){var driverName = this.props.driver.name}else{var driverName = this.props.driver.CompetitorName}}
        // if(driverName){
        //     var assName = driverName.indexOf('* ')
        //     if (assName != -1){
        //         var compName = driverName.replace('* ', '');
        //         var star_nbc = "#2faae1"
        //         var star_nfc = "#ffffff"
        //     }
        //     if (driverName){
        //         var competitorName = compName || driverName
        //         var run = 0
        //         while (run == 0){
        //             var nameArray = competitorName.split(",").join(" ").split(" ")
        //             if (run > 0){
        //                 var nameArray = competitorName.split("-").join(" ").split(" ")
        //                 var nameStart = nameArray[0]
        //                 var firstName = nameStart.substring(0,2*run+1)
        //             } else {
        //                 var firstName = nameArray[0][0]
        //                 var nameStart = nameArray[0]
        //             }
        //             nameArray.shift()
        //             var competitorName = firstName+". "+nameArray
        //             var remainingName = " " + nameArray
        //             run++
        //             competitorName = competitorName.replace(/,/g, ' ');
        //             remainingName = remainingName.replace(/,/g, ' ');
        //             if (run > 5){ break }
        //         }

        //     } else {
        //         var competitorName = compName || driverName
        //     }
        // } else {
        //     var competitorName = "undefined"
        // }
        //Smart Naming Calculations END

        //Assign colour for the colour bar
        var firstName = ""
        var lastName = ""
        if(driverName){
          var driverNameArray = driverName.split(" ")
          if(driverNameArray.length > 1){
            firstName = driverNameArray[0]
            lastName = driverName.replace(driverNameArray[0], "")
          }
        }
        
        const defaultColor = '#FF00FF' // Magenta color for the bar;
        const nationality = this.props.driver.chassis || this.props.driver.Chassis || this.props.driver.nat;
        const color = this.props.nationalityColors[nationality] || defaultColor;
        const teamName = this.props.driver.TeamName || this.props.driver.teamName;
        

        const containerStyles = {
          display: 'flex',
          alignItems: 'center', // Align items on the cross-axis
          backgroundColor: 'transparent',
          transform: "skew(-14deg)",
          width: (parseInt(this.props.ClientStates.GridRowWidth)+8)+"px",
          marginLeft: '-2px',
        };
          
      const itemStyles = {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          background: "linear-gradient(90deg, "+this.props.ClientStates.PrimaryColour+" 0%, "+this.props.ClientStates.PrimaryColour+" 8.5%, rgb(0, 0, 0) 8.7%, rgb(0, 0, 0) 9.7%, "+this.props.ClientStates.Nbc+" 9.8%, "+this.props.ClientStates.Nbc+" 20%, rgb(0, 0, 0) 20.2%, rgb(0, 0, 0) 21.2%, "+color+" 21.4%, "+color+" 22.5%, rgb(0, 0, 0) 22.7%,rgba(0, 0, 0, 0.90) 81.5%, rgba(0, 0, 0, 0.95) 81.8%, rgba(0, 0, 0, 0.95) 82.3%, rgba(0, 0, 0, 0.85) 82.5%)",
          // backgroundColor: this.props.ClientStates.TowerRowColour, // Dark background
          color: 'white',
          marginLeft: "-4.5px",
          width: (parseInt(this.props.ClientStates.GridRowWidth)+8)+"px",
          height: this.props.ClientStates.GridRowHeight
        };
      
        const rankStyles = {
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          alignItems: 'center',            
          fontFamily: 'Eurostile-bold',
          backgroundColor: "transparent", 
          color: 'white',
          fontSize: '30px', // Smaller than Number
          width: "30px",
          transform: "skew(8deg)",
          marginLeft: "22px",
          filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))"
        };
      
        const numberStyles = {
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          fontFamily: 'Eurostile-it',
          fontWeight: 'bold',
          alignItems: 'center', // Center vertically
          backgroundColor: 'transparent',
          color: 'black',
          fontSize: '35px',
          marginLeft: "50px",
          height: this.props.ClientStates.TowerRowNumberBoardHeight,
          width: "48px", // You've set a fixed width, so make sure it's enough to contain the number
          transform: "skew(14deg)",
          filter: "drop-shadow(1px 1px 0px rgba(0,0,0,0.4))",
      };
      
        const colorBarStyles = {
          width: '4px', // Thin color bar
          backgroundColor: color,
          margin: '2px 0px 2px 2px', // Space around the bar
          height: this.props.ClientStates.TowerRowNumberBoardHeight,
        };
      
        const nameStyles = {
          flex: 1, // Take up the remaining space
          display: 'flex',
          alignItems: 'center',
          marginLeft: '60px',
          fontFamily: 'Eurostile-Name',
          fontSize: "25px",
          overflowe: 'hidden',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          maxWidth: '140px',
          transform: "skew(14deg)",
          letterSpacing: '2.5px',
          // justifyContent: 'space-between', // Space between the name and the icon
        };
      
        const timeStyles = {
          fontFamily: 'Eurostile-Pro',
          fontWeight: 'regular',
          width: '65px',
          fontSize: '13px',
          textAlign: 'right',
          marginRight: '10px',
          lineHeight: '30px',
          transform: "skew(14deg)",
        };
      
        const iconContainerStyles = {
          display: 'flex',
          width: '40%',
          justifyContent: 'flex-end',
          alignItems: 'center'
        };
      
        const iconStyles = {
          height: '35px',
          marginRight: '30px',
          backgroundColor: 'transparent', // Placeholder for an icon
          transform: "skew(14deg)",
        };

        var marginTop = (parseInt(this.props.ClientStates.GridRowHeight) + parseInt(this.props.ClientStates.GridRowSpacing)) * this.props.id
        marginTop = marginTop + parseInt(this.props.Controls.GridsMargin)
          

        const spanStyles ={
          position: 'absolute',
          top: marginTop +"px",
          height: this.props.ClientStates.ResultRowHeight,
          width: this.props.ClientStates.ResultRowWidth,
          opacity: opacity,
          transition: "opacity 200ms ease-in-out, top 1000ms ease-in-out",
          transitionDelay: delay+"ms"
        }

        const leftContainerStyles = {
          display: 'flex',
          width: '60%',
          justifyContent: 'flex-start',
          
        }

        const leftTint = {
          position: "absolute",
          // backgroundColor: "grey",
          background: "linear-gradient(180deg, rgba(0, 0, 0,0.12) 0%, rgba(0, 0, 0, 0) 100%)",
          marginTop: ((parseInt(this.props.ClientStates.TowerRowHeight)/2))+"px",
          height:(parseInt(this.props.ClientStates.TowerRowHeight)/2)+"px", 
          width: "190px",
        }

          // const rowNumber = {
          //   position: 'absolute',
          //   marginLeft: '840px',
          //   textAlign: 'center',
          //   width: '60px',
          //   color: 'white',
          //   fontSize: '40px',
          //   fontFamily: 'Eurostile-bold'
          // }

          


          return (
            <div className={"AnimateMargin"} style={spanStyles}>
              <div style={containerStyles}>  
                <div id="mainRow" style={itemStyles}>
                    <div style={leftTint}></div>

                    <div style={rankStyles}>{this.props.driver.gridPosition || this.props.driver.GridPosition}</div>
                
                    <div style={leftContainerStyles}>

                        <div style={numberStyles}>{this.props.driver.CompetitorNumber || this.props.driver.number.number}</div>
                        {/* <div style={colorBarStyles}></div> */}

                        <div style={nameStyles}>
                            <span>{firstName.toUpperCase()}</span>
                            <div style={{fontFamily: 'Eurostile-Bold', marginLeft: '6px'}}>{lastName.toUpperCase()}</div>
                            <span></span>
                            {/* <div style={{fontFamily: 'Eurostile-Bold', marginLeft: '6px'}}>{this.props.driver.TeamName || lastName}</div> */}
                        </div>

                    </div>

                    
                    <div style={iconContainerStyles}>
                        {/* <img style={iconStyles} src={'https://alphalive.co.uk/TimingGraphics/KC/TeamLogos/Magik Kart USA.png'} onError={this.props.imageError}></img> */}
                        <img style={iconStyles} src={'https://alphalive.co.uk/TimingGraphics/KC/TeamLogos/'+this.props.driver.teamName || this.props.driver.TeamName+'.png'} onError={this.props.imageError}></img>
                    </div>
                </div>
              </div>
            </div>
          );

        
          
    }
}

export default GridItem ;