import React, { PureComponent } from 'react';
import TowerRows from './towerRows';
import './stylesheets/Tower.css';

class Tower extends PureComponent { 

    render() { 

        var transitionBattle = ""

        if(this.props.Controls.TowerShow == true){
            var towerClass = "TowerDiv_show"
        } else {
            var towerClass = "TowerDiv_hide"
        }

        //Vmix Check
        if(this.props.vmix == "true"){
            var divNudge = "7px"
        } else {
            var divNudge = "0px"
        }

        //Split Tower Size Calculations START
        var upperHeight = 350
        var lowerHeight = 350
        if(this.props.Controls.BattleShow == true && this.props.Session.Competitors.length > 20 && this.props.Controls.BattlePosition < 11){
            upperHeight = 380
        } else if ((this.props.Controls.BattleShow == true && this.props.Session.Competitors.length > 20 && this.props.Controls.BattlePosition > 11)){
            lowerHeight = 380
        }
        //Split Tower Size Calculations END

        //Battle Graphic Calculations START
            if (this.props.Controls.BattlePosition == 1){
                var battleEnd = "st"
                var battleMargin = 4
            } else if (this.props.Controls.BattlePosition == 2){
                var battleEnd = "nd"
                var battleMargin = 12+25
            } else if (this.props.Controls.BattlePosition == 3){
                var battleEnd = "rd"
                var battleMargin = 47+25
            } else {
                var battleEnd = "th"
                if (parseInt(this.props.Controls.BattlePosition) > 12){
                    var battleMargin = 12*35+4
                } else {
                    var battleMargin = (parseInt(this.props.Controls.BattlePosition)-1)*35+2
                }
            }
        //Battle Graphic Calculations END

        //Progression Graphic Calculations START
        var progressionClass = "ProgressionHide"
        var progressionBoost = 0
        
        if (this.props.Controls.Expand == true){
            var competitorsState = this.props.Session.Competitors
            var driverIndex = competitorsState.findIndex( x => x.CompetitorId == this.props.Controls.ExpandDriverID)
            if (driverIndex > -1){
                var expandPosition = competitorsState[driverIndex].Position

                if(expandPosition <= this.props.ClientStates.DefaultProgression){
                    progressionBoost = 72
                }

                if(expandPosition <= this.props.Controls.BattlePosition){
                    battleMargin = 47+25+73
                    transitionBattle = "margin-top 1000ms ease-in-out"
                }
            }
            
        }

        if (this.props.Controls.BattleShow == true && this.props.Controls.BattlePosition <= this.props.ClientStates.DefaultProgression){
            progressionBoost = progressionBoost + 27
        }

        var progressionMargin = this.props.Controls.ProgressionPosition * (this.props.ClientStates.TowerRowHeight+this.props.ClientStates.TowerRowSpacing) + 3 + progressionBoost

        if (this.props.Controls.ProgressionShow == true){
            upperHeight = upperHeight + 9
            progressionClass = "ProgressionShow"
        }

        if (this.props.Session.TSLGrouped == 1){
            var grouped = true
        } else {
            var grouped = false
        }
        //Progression Graphic Calculations END

        //Expanded Graphic Calculations START
        if (this.props.Controls.Expand == true && this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position < 11){
            upperHeight = upperHeight + 70
        } 
        //Expanded Graphic Calculations END
        var topTowerTotal = 20
        var bottomTowerTotal = 0
        if(this.props.Session){
            if (this.props.Session.Competitors){
                if (this.props.Session.Competitors.length > parseInt(this.props.ClientStates.TowerTotalPos)){
                    topTowerTotal = this.props.ClientStates.TowerFixedPos
                    bottomTowerTotal = parseInt(this.props.ClientStates.TowerTotalPos)-parseInt(topTowerTotal)
                } else {
                    topTowerTotal = this.props.ClientStates.TowerTotalPos
                    bottomTowerTotal = 0
                }
            }  
        }
        

        const TowerStyle = {
            height: (parseInt(this.props.ClientStates.TowerRowHeight) + parseInt(this.props.ClientStates.TowerRowSpacing)) * parseInt(topTowerTotal),
            overflow: 'hidden',
            marginLeft: divNudge, 
            marginTop: this.props.ClientStates.TowerGapToHeader
        }

        const TowerPages = {
            height: (parseInt(this.props.ClientStates.TowerRowHeight) + parseInt(this.props.ClientStates.TowerRowSpacing)) * parseInt(bottomTowerTotal),
            overflow: 'hidden',
            marginLeft: divNudge, 
            marginTop: this.props.ClientStates.TowerGapToHeader,
            backgroundColor: 'transparent'
        }

        const ProgressionBarActive = {
            position: 'absolute',
            backgroundColor: 'Red',
            height: this.props.ClientStates.TowerRowSpacing,
            width: this.props.ClientStates.TowerRowWidth,
            marginTop: this.props.Controls.ProgressionPosition*(parseInt(this.props.ClientStates.TowerRowHeight)+parseInt(this.props.ClientStates.TowerRowSpacing))-parseInt(this.props.ClientStates.TowerRowSpacing),
            marginLeft: parseInt(this.props.ClientStates.TowerIndent),
        }


            return ( 
                <div>
                    <div className={towerClass} style={TowerStyle}>
                        <div className="BattleGraphic" style={{marginTop: battleMargin+"px", height: '0px'}}>
                            <div className="BattleText">BATTLE FOR {this.props.Controls.BattlePosition}{battleEnd}</div>
                        </div>
                        <div className={progressionClass} style={ProgressionBarActive}></div>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TowerRows grouped={grouped} pages={false} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                    <div className={towerClass} style={TowerPages}>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TowerRows grouped={grouped} pages={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                </div>
            );

            
        }        
    }
export default Tower ;
