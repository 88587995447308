import React, { PureComponent } from 'react';

class ResultHeader  extends PureComponent { 
    render() {

        const resultHeaderStyles = {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: this.props.ClientStates.PrimaryColour, // Background colour for the header, set to the primary colour of the client
            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: '1px',
            color: '#fff',
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            height: '123px',
            overflow: 'hidden'
        };

        const logoContainerStyles = {
            backgroundColor: this.props.ClientStates.ThirdColour,
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            width: '615px',
            marginLeft: '-15px',
            height: '123px',
            transform: "Skew(-14deg)"
          };

        const logoStyles = {
            transform: "Skew(14deg)"
        };

        const titleStylesUpper = {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
            color: 'rgb(0 0 0)',
            fontFamily: 'Eurostile-WideBoldItalic',
            fontSize: '45px',
            height: '100%',
            padding: '0 0 0 70px',
            boxSizing: 'border-box',
            marginTop: '-4px',
            marginLeft: '-15px',
            filter: "drop-shadow(2px 2px 0px rgba(0,0,0,0.4))"
        };

        const titleStylesLower = {
            color: '#fff',
            fontSize: '45px',
            fontFamily: 'Eurostile-WideBoldItalic',
            marginTop: '-8px',
            marginLeft: '-15px',
            filter: "drop-shadow(2px 2px 0px rgba(0,0,0,0.4))"
        }

        const tint = {
            position: 'absolute',
            width: '1579px',
            background: "linear-gradient(180deg, rgba(0, 0, 0,0.12) 0%, rgba(0, 0, 0, 0) 100%)",
            marginTop: '61.5px',
            height: '61.5px',
            
        }

        return (
            <div style={resultHeaderStyles}>
                <div style={logoContainerStyles}>
                    <img src={"images/sponsorLogos/ResultLogo.png"} style={logoStyles}></img>
                </div>
                <div style={tint}></div>
                <div style={titleStylesUpper}>
                    UNOFFICIAL RESULTS
                <div style={titleStylesLower}>{this.props.Session.SessionName.toUpperCase()}</div>
                </div>
            </div>
        );
    }
    
}

export default ResultHeader ;