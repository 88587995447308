import React, { PureComponent } from 'react';
import JumboRow from './TowerRowStates/jumboRow';



class JumboTowerRows extends PureComponent {
    state = { 

     }
    render() { 
                return ( 
                    <JumboRow {...this.props}/>
                );
            } 
     }


 
export default JumboTowerRows;